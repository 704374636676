// takes games and returns games for user stats
export const gamesToStats = (games, user_id) => games.reduce((sum, game) => {
  const { _id, winner } = game
  const userGame = { _id, winner, players: [] }
  game.players.forEach(player => {
    if (player._id === user_id) {
      userGame.shots = player.shots
      userGame.score = player.score
    } else {
      userGame.players.push({ _id: player._id, name: player.name })
    }
  })
  return sum.concat(userGame)
}, []);

export const combinedToStats = (players, combinedGames) => {
  const games = {
    combinedGames: {}
  }

  players.forEach(player => {
    const { _id } = player
    games[_id] = []
    games['combinedGames'][_id] = []
  })

  combinedGames.forEach(game => {
    const combined = players.every(player => game.players.some(p => p._id === player._id)) ? true : false

    game.players.forEach(player => {
      const playerGame = {
        name: player.name,
        winner: game.winner,
        score: player.score
      }

      if (players.some(p => p._id === player._id)) {
        games[player._id].push(playerGame)
        if (combined) {
          games['combinedGames'][player._id].push(playerGame);
        }
      }
    })
  })

  const results = {
    combinedResults: {}
  }

  if (games['combinedGames'][players[0]._id].length) {
    players.forEach(player => {
      results['combinedResults'][player._id] = calcMatchUpStats(games['combinedGames'][player._id]);
    })
  }
  players.forEach(player => {
    results[player._id] = calcMatchUpStats(games[player._id]);
  })

  return results
}

export const calcMatchUpStats = (games) => {
  let wins = 0
  let best = 0
  let score = 0
  let avg = 0

  games.forEach(game => {
    if (game.winner === game.name) {
      wins++;
    }
    score += game.score
    if (game.score > best) {
      best = game.score;
    }
  })

  const gamesLength = games.length
  if (gamesLength) {
    avg = Math.round((score / gamesLength) * 100) / 100;
  }

  return {
    best,
    wins,
    avg
  }
}

export const calcScoreboardStats = (games) => {
  let wins = 0
  let best = 0
  let score = 0

  games.forEach(game => {
    if (game.winner === game.name) {
      wins++;
    }
    score += game.score
    best = game.score > best ? game.score : best
  })

  const gamesLength = games.length
  const avg = gamesLength ? Math.round((score / gamesLength) * 100) / 100 : 0

  return {
    best,
    wins,
    avg,
    score,
    gamesLength
  }
}

export const scoreboardToStats = (scoreboardGames, friends) => {
  const games = {}
  friends.forEach(friend => games[friend] = [])
  scoreboardGames.forEach(game => {
    game.players.forEach(player => {
      if (friends.includes(player._id)) {
        games[player._id].push({ name: player.name, winner: game.winner, score: player.score });
      }
    })
  })

  const results = {}
  friends.forEach(friend => results[friend] = calcScoreboardStats(games[friend]))

  return results
}

// calculates game stats from games
export const calcGameStats = (games, name) => {
  let bestGame = {
    score: 0
  }

  let longestGame = {
    rounds: 0
  }

  let totalShots = 0
  let totalRounds = 0
  let totalOTs = 0
  let totalOTRounds = 0
  let totalWins = 0
  let totalTies = 0

  games.forEach(game => {
    const score = game.score
    const rounds = game.shots.length
    totalShots += (rounds * 5)
    totalRounds += rounds
    if (rounds > 2) {
      totalOTs++
      totalOTRounds += (rounds - 2)
    }
    bestGame = score > bestGame.score ? game : bestGame
    if (rounds > longestGame.rounds) {
      longestGame = game
      longestGame.rounds = game.shots.length
    }
    const winner = game.winner;
    if (winner === name) {
      totalWins++
    }
    if (winner === 'tie') {
      totalTies++
    }
  })

  return {
    bestGame: bestGame,
    longestGame: longestGame,
    totalShots: totalShots,
    totalRounds: totalRounds,
    totalOTs: totalOTs,
    totalOTRounds: totalOTRounds,
    totalWins: totalWins,
    totalTies: totalTies
  }
}

// calculates shot stats from games
export const calcShotStats = (games) => {
  let totalMakes = 0
  let totalOTMakes = 0
  let shotMakes = Array(5).fill(0)
  let bestOf5 = {
    bestOf5: 0
  }
  let timesOf5 = Array(6).fill(0)
  let bestStreak = {
    streak: 0
  }

  games.forEach(game => {
    let rounds = 0
    game.shots.forEach(shots => {
      rounds++
      let shotNum = 0
      let currentScore = 0
      let currentStreak = 0
      shots.forEach(shot => {
        shotNum++
        totalMakes += shot
        if (rounds > 2) {
          totalOTMakes += shot
        }
        shotMakes[shotNum - 1] += shot
        currentScore += shot
        if (shot) {
          currentStreak++
          if (currentStreak > bestStreak.streak) {
            bestStreak = game
            bestStreak.streak = currentStreak
          }
        } else {
          currentStreak = 0
        }
      })
      if (currentScore > bestOf5.bestOf5) {
        bestOf5 = game
        bestOf5.bestOf5 = currentScore
      }
      timesOf5[currentScore]++
    })
  })

  return {
    totalMakes: totalMakes,
    totalOTMakes: totalOTMakes,
    shotMakes: shotMakes,
    bestOf5: bestOf5,
    timesOf5: timesOf5,
    bestStreak: bestStreak
  }
}

// calculates game stats for 10 from games
export const calcGameOf10Stats = (games) => {
  let bestGame = {
    currentScore: 0
  }
  let totalMakes = 0
  let front5Makes = 0
  let back5Makes = 0
  let shotMakes = Array(10).fill(0)
  let timesOf10 = Array(11).fill(0)

  games.forEach(game => {
    let currentScore = 0
    let shotNum = 0
    for (let i = 0; i < 2; i++) {
      // eslint-disable-next-line
      game.shots[i].forEach(shot => {
        shotNum++
        currentScore += shot
        totalMakes += shot
        if (i === 0) {
          front5Makes += shot;
        } else {
          back5Makes += shot;
        }
        shotMakes[shotNum - 1] += shot
      })
    }
    if (currentScore > bestGame.currentScore) {
      bestGame = game
      bestGame.currentScore = currentScore
    }
    timesOf10[currentScore]++
  })

  return {
    bestGame: bestGame,
    totalMakes: totalMakes,
    front5Makes: front5Makes,
    back5Makes: back5Makes,
    shotMakes: shotMakes,
    timesOf10: timesOf10
  }
}

export const calcSaveGame = (game) => {
  let bestScore = 0
  let best = 'tie'

  game.players.forEach(player => {
    if (player.score > bestScore) {
      best = player.name
      bestScore = player.score
    } else if (player.score === bestScore) {
      best = 'tie'
    }
  })
  game.winner = best

  return game
}

export const createNewGame = (players) => {
  const playing = players.reduce((total, player) => total.concat({ _id: player._id, name: player.name, score: 0, shots: [[0, 0, 0, 0, 0],[0, 0, 0, 0, 0]] }), [])

  return { turn: 0, players: playing }
}

export const calcGameEnd = (players, user_id) => {
  let bestScore = 0
  let best = 'tie'
  let friends = []
  let playing = false

  players.forEach(function(player) {
    playing = player._id === user_id ? true : playing

    friends.push(player._id)

    if (player.score > bestScore) {
      best = player.name
      bestScore = player.score
    } else if (player.score === bestScore) {
      best = 'tie'
    }
  })

  let game = {
    players: players,
    winner: best
  }

  return { game, playing, friends }
}
