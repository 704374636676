import PropTypes from 'prop-types';
import React from 'react'
import { ResponsiveContainer, LineChart, XAxis, YAxis, CartesianGrid, Line } from 'recharts'

const Chart = ({ data, xKey, yKey }) => {
  data.forEach((game, index) => {
    game['ind'] = index + 1
    return game
  })

  return (
    <ResponsiveContainer width="100%" height="100%">
      <LineChart data={data} barCategoryGap={1} margin={{top: 20, right: 100, bottom: 20, left: 40}}>
        <XAxis dataKey={xKey} tickLine={false} />
        <YAxis dataKey={yKey} tickLine={false} domain={[0, 10]} interval={0} />
        <CartesianGrid horizontal={false} vertical={false} />
        <Line dataKey={yKey} stroke="#424242" dot={false} />
      </LineChart>
    </ResponsiveContainer>
  )
}

Chart.propTypes = {
  data: PropTypes.array.isRequired,
  xKey: PropTypes.string,
  yKey: PropTypes.string
}

export default Chart
