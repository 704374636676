import PropTypes from 'prop-types';
import React from 'react'

const Stat = ({ number, name, game, percent, onClick }) => {

  const handleClick = () => onClick ? onClick(game) : null

  return (
    <div className="row-item" onClick={handleClick}>
      <div className="stat">
        <div className="stat-number">{number}</div>
        {percent && <div className="percent">%</div>}
      </div>
      <div className="stat-name">{name}</div>
    </div>
  )
}

Stat.propTypes = {
  number: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
  name: PropTypes.string,
  game: PropTypes.object,
  percent: PropTypes.bool,
  onClick: PropTypes.func
}

export default Stat
