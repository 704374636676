import initialState from './initialState'
import ActionTypes from '../actions/actionTypes'

const currentUserReducer = (state = initialState.currentUser, action) => {
  switch(action.type) {

    case ActionTypes.LOGIN_SUCCESS:
      return action.user

    case ActionTypes.SIGNUP_SUCCESS:
      return action.user

    case ActionTypes.LOGOUT:
      return initialState.currentUser

    case ActionTypes.LOAD_CURRENT_USER_REQUEST:
      return action.currentUser

    case ActionTypes.LOAD_CURRENT_USER_SUCCESS:
      return action.currentUser

    case ActionTypes.LOAD_CURRENT_USER_FAIL:
      return initialState.currentUser

    case ActionTypes.ADD_FRIENDS:
      return action.currentUser

    case ActionTypes.ADD_FRIEND_REQUEST:
      let { friends } = state
      return {
        ...state,
        friends: [ ...friends, action.user_id]
      }

    case ActionTypes.REMOVE_FRIEND_REQUEST:
      let user_id = action.user_id
      friends = state.friends
      friends = friends.filter(friend => friend !== user_id)
      return { ...state, friends }

    default:
      return state

  }
}

export default currentUserReducer
