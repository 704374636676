import PropTypes from 'prop-types';
import React from 'react'
import MatchUpRow from '../components/MatchUpRow'

const MatchUpContainer = ({ players, stats, onClickUser, loading }) => {
  const careerRow = { name: 'Career', wins: 'Wins', best: 'Best', avg: 'Avg' }
  const matchupRow = { name: 'Match up', wins: 'Wins', best: 'Best', avg: 'Avg' }

  const careerRows = players.map(player => {
    const { _id, name } = player
    const stat = stats[_id]
    const { wins, best, avg } = stat
    const row = { _id, name, wins, best, avg }
    return <MatchUpRow key={_id} row={row} onClickUser={onClickUser} />
  })

  const matchupRows = players
    .filter(player => stats['combinedResults'][player._id] ? true : false)
    .map(player => {
      const { _id, name } = player
      const { wins, best, avg } = stats['combinedResults'][_id]
      const row = { _id, name, wins, best, avg }
      return <MatchUpRow key={_id} row={row} onClickUser={onClickUser} />
    })

  const careerRowsLength = careerRows.length > 0
  const matchupRowsLength = matchupRows.length > 0

  return (
    <div className="match-up-section">
      { loading && <div>Loading...</div> }
      { matchupRowsLength && <MatchUpRow row={matchupRow} /> }
      { matchupRowsLength && matchupRows }
      { careerRowsLength && <MatchUpRow row={careerRow} /> }
      { careerRowsLength && careerRows }
    </div>
  )
}

MatchUpContainer.propTypes = {
  players: PropTypes.array.isRequired,
  stats: PropTypes.object.isRequired,
  onClickUser: PropTypes.func,
  loading: PropTypes.bool
}

export default MatchUpContainer
