import initialState from './initialState'
import ActionTypes from '../actions/actionTypes'

const newGameReducer = (state = initialState.newGame, action) => {
  switch(action.type) {

    case ActionTypes.START_GAME:
     return action.game

    case ActionTypes.CHANGE_SHOT:
      let { turn, index, shot } = action
      let players = state.players
      let playersLength = players.length
      let playerIndex = turn % playersLength
      let shotIndex = Math.floor(turn / playersLength)
      let player = players.slice(playerIndex, playerIndex + 1)[0]
      let playerShots = player.shots[shotIndex]

      let shots = [
        ...playerShots.slice(0, index),
        shot,
        ...playerShots.slice(index + 1),
      ]

      player.shots = [
        ...player.shots.slice(0, shotIndex),
        shots,
        ...player.shots.slice(shotIndex + 1)
      ]

      player.score = shot ? player.score + 1 : player.score - 1

      return {
        ...state,
        players: [ ...players.slice(0, playerIndex), player, ...players.slice(playerIndex + 1) ]
      }

    case ActionTypes.CHANGE_TURN:
      return { ...state, turn: action.turn }

    case ActionTypes.START_OT:
      return {
        turn: state.turn + 1,
        players: action.players
      }

    case ActionTypes.REMOVE_OT:
      return {
        turn: state.turn - 1,
        players: action.players
      }

    case ActionTypes.FINISH_GAME_REQUEST:
      return initialState.newGame

    case ActionTypes.START_REMATCH:
      return {
        turn: 0,
        players: action.players
      }

    default:
      return state
  }
}

export default newGameReducer
