import PropTypes from 'prop-types';
import React, { Component } from 'react'
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux'
import { loadGame } from '../actions/gameActions'
import { loadOtherUser } from '../actions/userActions'
import Header from '../components/common/Header'
import GamesContainer from '../containers/GamesContainer'
import Footer from '../components/common/Footer'

class OtherGamesPage extends Component {
  constructor(props) {
    super(props)

    this.handleLoadOtherUser = this.handleLoadOtherUser.bind(this)
    this.handleLoadGame = this.handleLoadGame.bind(this)
    this.handleBack = this.handleBack.bind(this)
  }

  componentWillMount() {
    if (this.props.otherUser.name === '') {
      this.props.history.push('/')
    }
  }

  handleLoadOtherUser(player) {
    this.props.loadOtherUser(player)
    this.props.history.push('/other')
  }

  handleLoadGame(game) {
    this.props.loadGame(game)
    this.props.history.push('/game')
  }

  handleBack() {
    this.props.history.goBack()
  }

  render() {
    const { currentUser, otherUser, otherGames, loading } = this.props

    return (
      <div>
        <Header name={otherUser.name} />
        <GamesContainer games={otherGames} currentUser={currentUser} user={otherUser} loading={loading} onClickGame={this.handleLoadGame} onClickUser={this.handleLoadOtherUser} />
        <Footer onClick={this.handleBack} name="Back" />
      </div>
    )
  }
}

OtherGamesPage.propTypes = {
  currentUser: PropTypes.object.isRequired,
  otherUser: PropTypes.object.isRequired,
  otherGames: PropTypes.array.isRequired
}

const mapStateToProps = (state) => {
  const { currentUser, otherUser, otherGames, loading } = state

  return {
    currentUser,
    otherUser,
    otherGames,
    loading
  }
}

OtherGamesPage = connect(
  mapStateToProps,
  { loadGame, loadOtherUser }
)(OtherGamesPage)

export default withRouter(OtherGamesPage)
