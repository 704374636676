import PropTypes from 'prop-types';
import React from 'react'
import CategoryRow from '../components/common/CategoryRow'
import FriendRow from '../components/FriendRow'

const FriendsContainer = ({ friends, others, onLoadUser, onAddFriend, onRemoveFriend, loading }) => {

  const createRows = (users, onClickAdd, friend) => users.map(user => <FriendRow key={user._id} user={user} onClickUser={onLoadUser} onClickAdd={onClickAdd} friend={friend} />)

  const friendsLength = friends.length > 0
  const othersLength = others.length > 0

  return (
    <div className="list-container games-list">
      { loading && !(friendsLength || othersLength) && <div>Loading...</div> }
      <CategoryRow name="Friends" />
      { friendsLength && createRows(friends, onRemoveFriend, true) }
      { othersLength && <CategoryRow name="Users" /> }
      { othersLength && createRows(others, onAddFriend, false) }
    </div>
  )
}

FriendsContainer.propTypes = {
  friends: PropTypes.array,
  others: PropTypes.array,
  onLoadUser: PropTypes.func.isRequired,
  onAddFriend: PropTypes.func.isRequired,
  onRemoveFriend: PropTypes.func.isRequired,
  loading: PropTypes.bool
}

export default FriendsContainer
