import ActionTypes from '../actions/actionTypes'
import initialState from './initialState'

const otherUserReducer = (state = initialState.otherUser, action) => {
  switch(action.type) {
    case ActionTypes.LOAD_OTHER_USER:
      return action.player

    case ActionTypes.REMOVE_OTHER_USER:
      return initialState.otherUser

    default:
      return state
  }
}

export default otherUserReducer
