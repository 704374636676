import PropTypes from 'prop-types';
import React from 'react'

const HeaderButtonRight = ({ children, onClick }) => (<button className="header-button header-button-right" onClick={onClick}>{children}</button>)

HeaderButtonRight.propTypes = {
  onClick: PropTypes.func.isRequired
}

export default HeaderButtonRight
