import PropTypes from 'prop-types';
import React, { Component } from 'react'
import Delete from 'react-icons/lib/io/trash-a'
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux'
import { editGame, deleteGame } from '../actions/gameActions'
import { loadOtherUser } from '../actions/userActions'
import HeaderButtonRight from '../components/common/HeaderButtonRight'
import GameContainer from '../containers/GameContainer'
import SecondFooter from '../components/common/SecondFooter'
import Footer from '../components/common/Footer'

class GamePage extends Component {
  constructor(props) {
    super(props)

    this.handleBack = this.handleBack.bind(this)
    this.handleEditGame = this.handleEditGame.bind(this)
    this.handleLoadOtherUser = this.handleLoadOtherUser.bind(this)
    this.handleDeleteGame = this.handleDeleteGame.bind(this)
  }

  componentWillMount() {
    if (this.props.game.winner === '') {
      this.props.history.push('/')
    }
  }

  handleEditGame() {
    const { game, editGame } = this.props
    editGame(game)
    this.props.history.push('/editGame')
  }

  handleLoadOtherUser(player) {
    this.props.loadOtherUser(player)
    this.props.history.push('/other')
  }

  handleDeleteGame() {
    const { game, deleteGame } = this.props
    deleteGame(game)
    this.props.history.goBack()
  }

  handleBack() {
    this.props.history.goBack()
  }

  render() {
    const { currentUser, game, otherUser } = this.props

    game.players = game.players.sort((first, second) => second.score - first.score)

    const edit = game.players.some(player => player._id === currentUser._id)

    return (
      <div className="page">
        {edit && <HeaderButtonRight onClick={this.handleDeleteGame}><Delete /></HeaderButtonRight>}
        <GameContainer game={game} currentUser={currentUser} user={otherUser} onClickUser={this.handleLoadOtherUser} />
        {edit && <SecondFooter onClick={this.handleEditGame} name="Edit" />}
        <Footer onClick={this.handleBack} name="Back" />
      </div>
    )
  }
}

GamePage.propTypes = {
  currentUser: PropTypes.object.isRequired,
  game: PropTypes.object.isRequired
}

const mapStateToProps = (state) => {
  const { currentUser, otherUser, game } = state

  return {
    currentUser,
    otherUser,
    game
  }
}

GamePage = connect(
  mapStateToProps,
  { editGame, deleteGame, loadOtherUser }
)(GamePage)

export default withRouter(GamePage)
