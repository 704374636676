import axios from 'axios'
import ActionTypes from './actionTypes'
import { createNewGame, calcGameEnd } from '../selectors/selectors'

// start new game with user
export const startNewGame = (user) => ({ type: ActionTypes.START_NEW_GAME, user })

// save playing users
export const savePlaying = (users) => ({ type: ActionTypes.SAVE_PLAYING, users })

// cancel playing
export const cancelPlaying = () => ({ type: ActionTypes.CANCEL_PLAYING })

// start new game
export const startGame = (players) => {
  const game = createNewGame(players)

  return { type: ActionTypes.START_GAME, game }
}

// change turn
export const changeTurn = (turn) => ({ type: ActionTypes.CHANGE_TURN, turn })

// change shot
export const changeShot = (turn, index, made) => {
  let shot = made ? 0 : 1

  return { type: ActionTypes.CHANGE_SHOT, turn, index, shot }
}

// start new round of over time
export const startOT = (players) => {
  players = players.map(player => ({ ...player, shots: player.shots.concat([[0, 0, 0, 0, 0]]) })) // add new round for each player

  return { type: ActionTypes.START_OT, players }
}

// remove last round of over time
export const removeOT = (players) => {
  players = players.map(player => ({ ...player, shots: player.shots.slice(0, player.shots.length - 1) })) // remove last round for each player

  return { type: ActionTypes.REMOVE_OT, players }
}

// finish game action
export const finishGame = (players, user_id) => (dispatch) => {
  const { game, playing, friends } = calcGameEnd(players, user_id)

  playing ? dispatch({ type: ActionTypes.FINISH_GAME_REQUEST, game }) : dispatch({ type: ActionTypes.FINISH_OTHER_GAME_REQUEST, game })

  axios.post('/api/game/create', { game }).then(
    response => playing ? dispatch({ type: ActionTypes.FINISH_GAME_SUCCESS, game: response.data }) : dispatch({ type: ActionTypes.FINISH_OTHER_GAME_SUCCESS, game: response.data }),
    error => console.log('Failed to load games', error.error)
  )

  axios.post('/api/friends/add', { friends, user_id }).then(
    response => dispatch({ type: ActionTypes.ADD_FRIENDS, currentUser: response.data }),
    error => console.log('Failed to add friends', error.error)
  )
}

export const startRematch = (players) => {
  players = players.map(player => ({ ...player, shots: [[0, 0, 0, 0, 0], [0, 0, 0, 0, 0]], score: 0 })) // reset each player for new game

  return { type: ActionTypes.START_REMATCH, players }
}
