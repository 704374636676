import PropTypes from 'prop-types';
import React from 'react'

const ThirdFooter = ({ name, onClick }) => (<button className="footer third-footer" onClick={onClick}>{name}</button>)

ThirdFooter.propTypes = {
  onClick: PropTypes.func.isRequired,
  name: PropTypes.string
}

export default ThirdFooter
