import PropTypes from 'prop-types';
import React, { Component } from 'react'
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux'
import { loadGame } from '../actions/gameActions'
import { loadOtherUser } from '../actions/userActions'
import Header from '../components/common/Header'
import GamesContainer from '../containers/GamesContainer'
import Footer from '../components/common/Footer'

class GamesPage extends Component {
  constructor(props) {
    super(props)

    this.handleBack = this.handleBack.bind(this)
    this.handleLoadOtherUser = this.handleLoadOtherUser.bind(this)
    this.handleLoadGame = this.handleLoadGame.bind(this)
  }

  componentWillMount() {
    if (this.props.games.length === 0) {
      this.props.history.push('/')
    }
  }

  handleLoadOtherUser(player) {
    this.props.loadOtherUser(player)
    this.props.history.push('/other')
  }

  handleLoadGame(game) {
    this.props.loadGame(game)
    this.props.history.push('/game')
  }

  handleBack() {
    this.props.history.push('/')
  }

  render() {
    const { currentUser, games, loading } = this.props

    return (
      <div className="page">
        <Header name={currentUser.name} />
        <GamesContainer games={games} currentUser={currentUser} user={currentUser} loading={loading} onClickGame={this.handleLoadGame} onClickUser={this.handleLoadOtherUser} />
        <Footer onClick={this.handleBack} name="Back" />
      </div>
    )
  }
}

GamesPage.propTypes = {
  currentUser: PropTypes.object.isRequired,
  games: PropTypes.array.isRequired
}

const mapStateToProps = (state) => {
  const { currentUser, games, loading } = state

  return {
    currentUser,
    games,
    loading
  }
}

GamesPage = connect(
  mapStateToProps,
  { loadGame, loadOtherUser }
)(GamesPage)

export default withRouter(GamesPage)
