import PropTypes from 'prop-types';
import React from 'react'

const SecondFooter = ({ name, onClick }) => (<button className="footer second-footer" onClick={onClick}>{name}</button>)

SecondFooter.propTypes = {
  onClick: PropTypes.func.isRequired,
  name: PropTypes.string
}

export default SecondFooter
