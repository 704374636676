import PropTypes from 'prop-types';
import React from 'react'
import Make from 'react-icons/lib/io/ios-basketball'
import Miss from 'react-icons/lib/io/ios-basketball-outline'

const Shots = ({ index, shots, onChange }) => {
  const handleChange = (i, shot) => onChange ? onChange(index, i, shot) : null

  const hoops = shots.reduce((total, shots) => total.concat(shots.reduce((round, shot) => round.concat(shot), [])), [])

  return (
    <div className="shots">
      {hoops.map((shot, ind) =>
        <div key={ind} className="shot" onClick={() => handleChange(ind, shot)}>
          <input className="shot-checkbox" type="checkbox" checked={shot} readOnly />
          <label className="shot-label">{shot ? <Make className="made-shot" size={40} /> : <Miss size={40} />}</label>
        </div>
      )}
    </div>
  )
}

Shots.propTypes = {
  index: PropTypes.number,
  shots: PropTypes.array.isRequired,
  onChange: PropTypes.func
}

export default Shots
