import ActionTypes from '../actions/actionTypes'
import initialState from './initialState'

const editGameReducer = (state = initialState.editGame, action) => {
  switch(action.type) {
    case ActionTypes.EDIT_GAME:
      return action.game

    case ActionTypes.CANCEL_EDIT_GAME:
      return initialState.editGame

    case ActionTypes.SAVE_GAME_REQUEST:
      return initialState.editGame

    case ActionTypes.EDIT_SHOT:
      let { index, shot, made } = action

      let game = state
      let players = game.players
      let player = players[index]
      let score = player.score
      let shots = player.shots

      let round = Math.floor(shot / 5)

      shot = shot % 5
      made = made ? 0 : 1
      score = made ? score + 1 : score - 1

      shots = [
        ...shots.slice(0, round),
        [
          ...shots[round].slice(0, shot),
          made,
          ...shots[round].slice(shot + 1)
        ],
        ...shots.slice(round + 1)
      ]

      player = {
        ...player,
        shots: shots,
        score: score
      }

      return {
        ...game,
        players: [
          ...players.slice(0, index),
          player,
          ...players.slice(index + 1)
        ]
      }

    default:
      return state
  }
}

export default editGameReducer
