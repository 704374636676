import PropTypes from 'prop-types';
import React from 'react'

const Footer = ({ name, onClick }) => (<button className="footer" onClick={onClick}>{name}</button>)

Footer.propTypes = {
  onClick: PropTypes.func.isRequired,
  name: PropTypes.string
}

export default Footer
