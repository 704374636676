import axios from 'axios'
import ActionTypes from './actionTypes'
import { loadGames } from './gameActions'

// change login/signup name
export const changeName = (name) => ({ type: ActionTypes.CHANGE_NAME, name })

// change login/signup password
export const changePassword = (password) => ({ type: ActionTypes.CHANGE_PASSWORD, password })

// switch between login and signup
export const changeLogin = () => ({ type: ActionTypes.SWITCH_LOGIN })

// user pressed login
export const login = (name, password) => (dispatch) => {
  dispatch({ type: ActionTypes.LOGIN_REQUEST })  // send login request

  return axios.post('/api/login', { name, password }).then(
    response => {
      dispatch({ type: ActionTypes.LOGIN_SUCCESS, user: response.data })  // login successful
      dispatch(loadGames(response.data._id)) // load games for current user
    },
    error => dispatch({ type: ActionTypes.LOGIN_FAIL, error: 'Username or password is incorrect' })  // login failed
  )
}

// sign up clicked
export const signup = (name, password) => (dispatch) => {
  dispatch({ type: ActionTypes.SIGNUP_REQUEST }) // signup requested

  return axios.post('/api/signup', { name, password }).then(
    response => dispatch({ type: ActionTypes.SIGNUP_SUCCESS, user: response.data }), // signup successful
    error => dispatch({ type: ActionTypes.SIGNUP_FAIL, error: 'Username taken' }) //signup failed
  )
}
