import PropTypes from 'prop-types';
import React from 'react'
import Plus from 'react-icons/lib/io/plus'
import Minus from 'react-icons/lib/io/close'

const PlayingRow = ({ user, onClick }) => (
  <div className="playing-row" onClick={() => onClick(user)}>
      <div className="playing-name">{user.name}</div>
      <input className="playing-checkbox" type="checkbox" checked={user.checked} readOnly />
      <label className={user.checked ? "remove-label" : "add-label"}>{user.checked ? <Minus /> : <Plus />}</label>
  </div>
)

PlayingRow.propTypes = {
  user: PropTypes.object.isRequired,
  onClick: PropTypes.func.isRequired
}

export default PlayingRow
