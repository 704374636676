import PropTypes from 'prop-types';
import React from 'react'
import Shots from './Shots'

const Game = ({ game, user, currentUser, onClickUser, onClickGame }) => {
  const handleLoadGame = () => onClickGame ? onClickGame(game) : null

  user = user ? user : currentUser

  const player = game.players.find(player => player._id === user._id)
  const players = game.players.filter(player => player._id !== user._id)

  let result = ''
  const gameLength = game.players[0].shots.length
  if (gameLength > 3) {
    result += (gameLength - 2).toString();
  }

  if (game.winner === 'tie' && game.players[0].shots.length > 2) {
    result += 'OT/T'
  } else if (game.winner === 'tie') {
    result += 'T'
  } else if (game.winner === user.name && game.players[0].shots.length > 2) {
    result += 'OT/W'
  } else if (game.winner === user.name) {
    result += 'W'
  } else if (game.players[0].shots.length > 2) {
    result += 'OT/L'
  } else {
    result += 'L'
  }

  return (
    <div className="game">
      <div className="game-player" onClick={handleLoadGame}>
        <div className="game-result">{result}</div>
        <div className="game-shots">
          <Shots shots={player.shots} />
        </div>
        <div className="game-score">{player.score}</div>
      </div>
      <div className="game-players">
        with {players.map((player, index) => (<div className="with-player" key={index} onClick={() => onClickUser(player)}>{player.name} {player.score} </div>))}
      </div>
      <br/><br/>
    </div>
  )
}

Game.propTypes = {
  game: PropTypes.object.isRequired,
  currentUser: PropTypes.object.isRequired,
  user: PropTypes.object,
  onClickGame: PropTypes.func,
  onClickUser: PropTypes.func
}

export default Game
