import PropTypes from 'prop-types';
import React from 'react';

const SearchBar = ({ search, onChange }) => (
  <form className="search-bar">
    <input className="search-input" type="text" placeholder="search..." value={search} onChange={onChange} />
  </form>
)

SearchBar.propTypes = {
  search: PropTypes.string,
  onChange: PropTypes.func.isRequired,
}

export default SearchBar
