import React from 'react'
import { Link } from 'react-router-dom'

const NotFoundPage = () => (
	<div className="page">
		<div className="container">
			<div>Page not found</div>
			<div>Whoops! Sorry, there is nothing to see here.</div>
			<div><Link to="/">Back to home</Link></div>
		</div>
	</div>
)

export default NotFoundPage
