import PropTypes from 'prop-types';
import React from 'react'

const Rebounder = ({ rebounder }) => (<div className="rebounder">Rebounder: {rebounder.name}</div>)

Rebounder.propTypes = {
  rebounder: PropTypes.object.isRequired
}

export default Rebounder
