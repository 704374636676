import PropTypes from 'prop-types';
import React from 'react'
import Game from '../components/Game'

const GamesContainer = ({ games, currentUser, user, loading, onClickGame, onClickUser }) => {
  if (games.length === 0 && loading) {
    return (<div>Loading...</div>)
  } else if (games.length === 0) {
    return (<div>No games</div>)
  }

  return (
    <div className="container">
        {games.map((game, index) => <Game key={index} game={game} currentUser={currentUser} user={user} onClickGame={onClickGame} onClickUser={onClickUser} />)}
    </div>
  )
}

GamesContainer.propTypes = {
  games: PropTypes.array.isRequired,
  currentUser: PropTypes.object.isRequired,
  user: PropTypes.object,
  loading: PropTypes.bool,
  onClickGame: PropTypes.func,
  onClickUser: PropTypes.func
}

export default GamesContainer
