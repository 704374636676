import PropTypes from 'prop-types';
import React from 'react'
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux'
import { startRematch } from '../actions/newGameActions'
import { editGame } from '../actions/gameActions'
import { loadOtherUser } from '../actions/userActions'
import { combinedToStats } from '../selectors/selectors'
import Header from '../components/common/Header'
import GameContainer from '../containers/GameContainer'
import MatchUpContainer from '../containers/MatchUpContainer'
import ThirdFooter from '../components/common/ThirdFooter'
import SecondFooter from '../components/common/SecondFooter'
import Footer from '../components/common/Footer'

class GameOverPage extends React.Component {
  constructor(props) {
    super(props)

    this.handleLoadOtherUser = this.handleLoadOtherUser.bind(this)
    this.handleEditGame = this.handleEditGame.bind(this)
    this.handleRematch = this.handleRematch.bind(this)
    this.handleDone = this.handleDone.bind(this)
  }

  componentDidMount() {
    if (this.props.game.players.length === 0) {
      this.props.history.push('/')
    }
  }

  handleLoadOtherUser(player) {
    this.props.loadOtherUser(player)
    this.props.history.push('/other')
  }

  handleEditGame() {
    const { game, editGame } = this.props
    editGame(game)
    this.props.history.push('/editGame')
  }

  handleRematch() {
    const { game, startRematch } = this.props
    startRematch(game.players)
    this.props.history.push('/playGame')
  }

  handleDone() {
    this.props.history.push('/')
  }

  render() {
    const { game, currentUser, playing, combinedGames, stats } = this.props

    return (
      <div className="page">
        <Header name="Game over" />
        <div className="page-container three-footer">
          <GameContainer game={game} user={currentUser} onClickUser={this.handleLoadOtherUser} />
          { combinedGames.length !== 0 ? <MatchUpContainer players={playing} stats={stats} onClickUser={this.handleLoadOtherUser} /> : null }
        </div>
        <ThirdFooter onClick={this.handleEditGame} name="Edit" />
        <SecondFooter onClick={this.handleDone} name="Done" />
        <Footer onClick={this.handleRematch} name="Play again" />
      </div>
    );
  }
}

GameOverPage.propTypes = {
  game: PropTypes.object.isRequired
}

const mapStateToProps = (state) => {
  const { game, currentUser, playing, combinedGames } = state

  const stats = combinedGames.length > 0 ? combinedToStats(playing, combinedGames) : {}

  return {
    game,
    currentUser,
    playing,
    combinedGames,
    stats
  }
}

GameOverPage = connect(
  mapStateToProps,
  { startRematch, editGame, loadOtherUser }
)(GameOverPage)

export default withRouter(GameOverPage)
