import initialState from './initialState'
import ActionTypes from '../actions/actionTypes'

const scoreboardGamesReducer = (state = initialState.scoreboardGames, action) => {
  switch(action.type) {

    case ActionTypes.LOAD_SCOREBOARD_GAMES_REQUEST:
      return initialState.scoreboardGames

    case ActionTypes.LOAD_SCOREBOARD_GAMES_SUCCESS:
      return action.games

    case ActionTypes.REMOVE_SCOREBOARD:
      return initialState.scoreboardGames

    default:
      return state
  }
}

export default scoreboardGamesReducer
