import PropTypes from 'prop-types';
import React, { Component } from 'react'
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux'
import { cancelEditGame, editShot, deleteGame, saveGame } from '../actions/gameActions'
import Header from '../components/common/Header'
import GameContainer from '../containers/GameContainer'
import SecondFooter from '../components/common/SecondFooter'
import Footer from '../components/common/Footer'

class EditGamePage extends Component {
  constructor(props) {
    super(props)

    this.handleEditShot = this.handleEditShot.bind(this)
    this.handleCancel = this.handleCancel.bind(this)
    this.handleSave = this.handleSave.bind(this)
  }

  componentWillMount() {
    if (this.props.editGame.players.length === 0) {
      this.props.history.push('/games')
    }
  }

  handleEditShot(index, shot, made) {
    this.props.editShot(index, shot, made)
  }

  handleSave() {
    const { currentUser, editGame, saveGame } = this.props
    saveGame(editGame, currentUser._id)
    this.props.history.goBack()
  }

  handleCancel() {
    this.props.cancelEditGame()
    this.props.history.goBack()
  }

  render() {
    const { editGame, currentUser } = this.props

    return (
      <div className="page">
        <Header name="Edit game" />
        <GameContainer
          game={editGame}
          user={currentUser}
          onChange={this.handleEditShot}
        />
        <SecondFooter onClick={this.handleCancel} name="Cancel" />
        <Footer onClick={this.handleSave} name="Save" />
      </div>
    )
  }
}

EditGamePage.propTypes = {
  editGame: PropTypes.object.isRequired
}

const mapStateToProps = (state) => {
  const { editGame, currentUser } = state

  return {
    editGame,
    currentUser
  }
}

EditGamePage = connect(
  mapStateToProps,
  { cancelEditGame, editShot, saveGame, deleteGame }
)(EditGamePage)

export default withRouter(EditGamePage);
