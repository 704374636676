import { combineReducers } from 'redux'
import loading from './loadingReducer'
import error from './errorReducer'
import search from './searchReducer'
import sortBy from './sortByReducer'
import loginState from './loginReducer'
import currentUser from './currentUserReducer'
import games from './gamesReducer'
import game from './gameReducer'
import editGame from './editGameReducer'
import playing from './playingReducer'
import newGame from './newGameReducer'
import users from './usersReducer'
import otherUser from './otherUserReducer'
import otherGames from './otherGamesReducer'
import combinedGames from './combinedGamesReducer'
import scoreboardGames from './scoreboardGamesReducer'

const rootReducer = combineReducers({
  loading,
  error,
  loginState,
  search,
  sortBy,
  currentUser,
  games,
  game,
  editGame,
  playing,
  newGame,
  users,
  otherUser,
  otherGames,
  combinedGames,
  scoreboardGames
})

export default rootReducer
