import PropTypes from 'prop-types';
import React from 'react'
import Player from '../components/Player'

const GameContainer = ({ game, user, onClickUser, onChange }) => (
  <div className="game-section">
      {game.players.map((player, index) => <Player key={index} index={index} player={player} onClickUser={onClickUser} onChange={onChange} />)}
  </div>
)

GameContainer.propTypes = {
  game: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  onClickUser: PropTypes.func,
  onChange: PropTypes.func
}

export default GameContainer
