import initialState from './initialState'
import ActionTypes from '../actions/actionTypes'

const gamesReducer = (state = initialState.games, action) => {
  switch(action.type) {

    case ActionTypes.LOGOUT:
      return initialState.games

    case ActionTypes.LOAD_GAMES_SUCCESS:
      return action.games

    case ActionTypes.FINISH_GAME_SUCCESS:
      return [ ...state, action.game ]

    case ActionTypes.DELETE_GAME_REQUEST:
      return state.filter(game => game._id !== action.game_id)

    case ActionTypes.DELETE_GAME_FAIL:
      return [ ...state, action.game ]

    case ActionTypes.SAVE_GAME_REQUEST:
      const saveGame = action.game
      const games = state
      let index = games.findIndex(game => game._id === saveGame._id)
      return index === -1 ? games : [ ...games.slice(0, index), saveGame, ...games.slice(index + 1) ]

    default:
      return state
  }
}

export default gamesReducer
