import initialState from './initialState'
import ActionTypes from '../actions/actionTypes'

const loadingReducer = (state = initialState.loading, action) => {
  switch(action.type) {

    case ActionTypes.SWITCH_LOGIN:
      return false

    case ActionTypes.LOGIN_REQUEST:
      return true

    case ActionTypes.LOGIN_SUCCESS:
      return false

    case ActionTypes.LOGIN_FAIL:
      return false

    case ActionTypes.SIGNUP_REQUEST:
      return true

    case ActionTypes.SIGNUP_SUCCESS:
      return false

    case ActionTypes.SIGNUP_FAIL:
      return false

    case ActionTypes.LOGOUT:
      return false

    case ActionTypes.LOAD_USERS_REQUEST:
      return true

    case ActionTypes.LOAD_USERS_SUCCESS:
      return false

    case ActionTypes.LOAD_USERS_FAIL:
      return false

    case ActionTypes.LOAD_GAMES_REQUEST:
      return true

    case ActionTypes.LOAD_GAMES_SUCCESS:
      return false

    case ActionTypes.LOAD_GAMES_FAIL:
      return false

    case ActionTypes.LOAD_OTHER_USER:
      return true

    case ActionTypes.LOAD_OTHER_GAMES_SUCCESS:
      return false

    case ActionTypes.LOAD_OTHER_GAMES_FAIL:
      return false

    case ActionTypes.LOAD_COMBINED_GAMES_REQUEST:
      return true

    case ActionTypes.LOAD_COMBINED_GAMES_SUCCESS:
      return false

    case ActionTypes.LOAD_COMBINED_GAMES_FAIL:
      return false

    case ActionTypes.LOAD_SCOREBOARD_GAMES_REQUEST:
      return true

    case ActionTypes.LOAD_SCOREBOARD_GAMES_SUCCESS:
      return false

    case ActionTypes.LOAD_SCOREBOARD_GAMES_FAIL:
      return false

    default:
      return state
  }
}

export default loadingReducer
