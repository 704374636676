import PropTypes from 'prop-types';
import React from 'react'

const Header = ({ name }) => (
  <div className="header">
    <div className="title">{name}</div>
  </div>
)

Header.propTypes = {
  name: PropTypes.string
}

export default Header
