import axios from 'axios'
import ActionTypes from './actionTypes'
import { calcSaveGame } from '../selectors/selectors'

// load game
export const loadGame = (game) => ({ type: ActionTypes.LOAD_GAME, game })

// load games for current user
export const loadGames = (user_id) => (dispatch) => {
  dispatch({ type: ActionTypes.LOAD_GAMES_REQUEST }) // load games requested

  return axios.post('/api/games', { user_id }).then(
    response => dispatch({ type: ActionTypes.LOAD_GAMES_SUCCESS, games: response.data }), // load games success
    error => dispatch({ type: ActionTypes.LOAD_GAMES_FAIL, error: 'Failed to load games' }) // load games failed
  )
}

// load combined games for users
export const loadCombinedGames = (users) => (dispatch) => {
  dispatch({ type: ActionTypes.LOAD_COMBINED_GAMES_REQUEST }) // load games requested

  return axios.post('/api/games/combined', { users }).then(
    response => dispatch({ type: ActionTypes.LOAD_COMBINED_GAMES_SUCCESS, games: response.data }), // load games success
    error => dispatch({ type: ActionTypes.LOAD_COMBINED_GAMES_FAIL, error: 'Failed to load games' }) // load games failed
  )
}

// deleting game
export const deleteGame = (game) => (dispatch) => {
  dispatch({ type: ActionTypes.DELETE_GAME_REQUEST, game_id: game._id }) // delete game requested

  return axios.post('/api/game/delete', { game_id: game._id }).then(
    response => dispatch({ type: ActionTypes.DELETE_GAME_SUCCESS, game_id: game._id }), // delete game success
    error => dispatch({ type: ActionTypes.DELETE_GAME_FAIL, game, error: 'Failed to delete game' }) // delete game failed
  )
}

// edit game
export const editGame = (game) => ({ type: ActionTypes.EDIT_GAME, game })

// cancel edit game
export const cancelEditGame = () => ({ type: ActionTypes.CANCEL_EDIT_GAME })

// edit shot
export const editShot = (index, shot, made) => ({ type: ActionTypes.EDIT_SHOT, index, shot, made })

// save game
export const saveGame = (game, user_id) => (dispatch) => {
  game = calcSaveGame(game)

  dispatch({ type: ActionTypes.SAVE_GAME_REQUEST, game })

  return axios.post('/api/game/update', { game }).then(
    response => null,
    error => dispatch({ type: ActionTypes.SAVE_GAME_FAIL, error: 'Failed to save game' })
  )
}

// loading scoreboard games
export const loadFriendScoreboard = (friends) => (dispatch) => {
  dispatch({ type: ActionTypes.LOAD_SCOREBOARD_GAMES_REQUEST }) // load scoreboard games requested

  return axios.post('/api/scoreboard/friends', { friends }).then(
    response => dispatch({ type: ActionTypes.LOAD_SCOREBOARD_GAMES_SUCCESS, games: response.data }), // load scoreboard games success
    error => dispatch({ type: ActionTypes.LOAD_SCOREBOARD_GAMES_FAIL, error: 'Failed to delete game' }) // load scoreboard games failed
  )
}

export const removeScoreboard = () => ({ type: ActionTypes.REMOVE_SCOREBOARD})
