import PropTypes from 'prop-types';
import React from 'react'
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux'
import { savePlaying, cancelPlaying  } from '../actions/newGameActions'
import { setError, setSearch } from '../actions/userActions'
import { loadCombinedGames } from '../actions/gameActions'
import Header from '../components/common/Header'
import PlayingContainer from '../containers/PlayingContainer'
import SecondFooter from '../components/common/SecondFooter'
import Footer from '../components/common/Footer'

class PlayingPage extends React.Component {
  constructor(props) {
    super(props)

    this.handleNext = this.handleNext.bind(this)
    this.handleChange = this.handleChange.bind(this)
    this.handleSearchChange = this.handleSearchChange.bind(this)
    this.handleCancel = this.handleCancel.bind(this)
  }

  handleChange(player) {
    const { playing, savePlaying } = this.props
    delete player['checked']
    const players = playing.some(user => user._id === player._id) ? playing.filter(e => e._id !== player._id) : playing.concat(player)
    savePlaying(players)
  }

  handleNext(event) {
    event.preventDefault();
    const { playing, setError, loadCombinedGames } = this.props

    if (playing.length <= 1) {
      setError('Please select more than one player')
      return
    }

    const users = playing.reduce((total, player) => total.concat(player._id), [])
    loadCombinedGames(users)

    this.props.history.push('/order')
  }

  handleSearchChange(evt) {
    this.props.setSearch(evt.target.value)
  }

  handleCancel() {
    const { cancelPlaying, setSearch } = this.props
    cancelPlaying()
    setSearch('')
    this.props.history.push('/')
  }

  render() {
    const { currentUser, friends, users, playing, search, loading, error } = this.props

    return (
      <div className="page">
        <Header name="Playing" />
        <PlayingContainer
          user={currentUser}
          friends={friends}
          users={users}
          playing={playing}
          onClick={this.handleChange}
          error={error}
          loading={loading}
          search={search}
          onChange={this.handleSearchChange}
          onClear={this.handleClearSearch}
        />
        <SecondFooter onClick={this.handleCancel} name="Cancel" />
        <Footer onClick={this.handleNext} name="Next" />
      </div>
    );
  }
}

PlayingPage.propTypes = {
  currentUser: PropTypes.object.isRequired,
  users: PropTypes.array.isRequired,
  playing: PropTypes.array.isRequired,
  search: PropTypes.string,
  loading: PropTypes.bool,
  error: PropTypes.string
}

const mapStateToProps = (state) => {
  let { currentUser, users, playing, search, loading, error } = state

  const friends = users.filter(user => user._id !== currentUser._id && currentUser.friends.includes(user._id))

  users = users.filter(user => user._id !== currentUser._id && !currentUser.friends.includes(user._id) && user.name.indexOf(search) >= 0)

  return {
    currentUser,
    friends,
    users,
    playing,
    search,
    loading,
    error
  }
}

PlayingPage = connect(
  mapStateToProps,
  { savePlaying, setError, loadCombinedGames, cancelPlaying, setSearch }
)(PlayingPage)

export default withRouter(PlayingPage)
