// src/App.js

import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import * as userActions from './actions/userActions';
import routes from './routes';

class App extends Component {
  componentDidMount() {
    const {
      currentUser,
      history,
    } = this.props;

    if (!currentUser._id) {
      history.replace('/login');
    } else {
      this.props.loadUsers();
    }
  }

  componentWillReceiveProps(nextProps) {
    const { currentUser: nextCurrentUser } = nextProps;
    const { currentUser, history } = this.props;

    if (currentUser._id && !nextCurrentUser._id) {
      console.log('logout');
      history.replace('/login');
    }
  }

  render() {
    return (
      <div className="app">
        {routes}
      </div>
    );
  }
}

App.propTypes = {
  currentUser: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  loadUsers: PropTypes.func.isRequired,
};

const mapStateToProps = ({ currentUser }) => ({
  currentUser
});

export default withRouter(
  connect(mapStateToProps, {
    ...userActions
  })(App)
);
