import PropTypes from 'prop-types';
import React from 'react'
import Drag from 'react-icons/lib/io/drag'
import { SortableContainer, SortableElement, arrayMove } from 'react-sortable-hoc'

const SortableItem = SortableElement(({value}) => (
  <li className="order-item">
    <div className="order-name">{value}</div>
    <div className="order-item-drag"><Drag /></div>
  </li>
))

const SortableList = SortableContainer(({players}) => {
  return (
    <ul className="order-list">
      {players.map((player, index) =>
        <SortableItem key={player.name} index={index} value={player.name} />
      )}
    </ul>
  )
})

const OrderContainer = ({ players, onChange }) => {

  const handleSortEnd = (index) => onChange(arrayMove(players, index.oldIndex, index.newIndex))

  return (
    <div className="order-section">
      <SortableList players={players} onSortEnd={handleSortEnd} axis="y" lockAxis="y" />
    </div>
  )
}

OrderContainer.propTypes = {
  players: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired
}

export default OrderContainer
