import axios from 'axios'
import ActionTypes from './actionTypes'

// set universal error
export const setError = (error) => ({ type: ActionTypes.SET_ERROR, error })

const loadCurrentUserSuccess = (currentUser) => ({ type: ActionTypes.LOAD_CURRENT_USER_SUCCESS, currentUser })

// load current user
export const loadCurrentUser = (currentUser) => (dispatch) => {
  dispatch({ type: ActionTypes.LOAD_CURRENT_USER_REQUEST, currentUser }) // request current user

  return axios.post('/api/user', { user_id: currentUser._id }).then(
    response => dispatch(loadCurrentUserSuccess(response.data)), // load current user successful
    error => dispatch({ type: ActionTypes.LOAD_CURRENT_USER_FAIL, error: 'Failed to load user' }) // failed to load current user
  )
}

// loading all users
export const loadUsers = ()  => (dispatch) => {
  dispatch({ type: ActionTypes.LOAD_USERS_REQUEST }) // send load users

  return axios.get('/api/users').then(
    response => dispatch({ type: ActionTypes.LOAD_USERS_SUCCESS, users: response.data }), // load users successful
    error => dispatch({ type: ActionTypes.LOAD_USERS_FAIL, error: 'Failed to load users' }) // load users failed
  )
}

// logout user
export const logout = () => ({ type: ActionTypes.LOGOUT })

// load other user
export const loadOtherUser = (player) => (dispatch) => {
  dispatch({ type: ActionTypes.LOAD_OTHER_USER, player }) // load other user

  return axios.post('/api/games', { user_id: player._id }).then(
    response => dispatch({type: ActionTypes.LOAD_OTHER_GAMES_SUCCESS, games: response.data }), // load other user games success
    error => dispatch({type: ActionTypes.LOAD_OTHER_GAMES_FAIL, error: "Failed to load games"}) // load other user games failed
  )
}

// remove other player
export const removeOtherUser = () => ({ type: ActionTypes.REMOVE_OTHER_USER })

// add friend request
const addFriendRequest = (user_id) => ({ type: ActionTypes.ADD_FRIEND_REQUEST, user_id })

// remove friend request
const removeFriendRequest = (user_id) => ({ type: ActionTypes.REMOVE_FRIEND_REQUEST, user_id })

// load other user
export const addFriend = (user_id, currentUser_id) => (dispatch) => {
  dispatch(addFriendRequest(user_id)) // add friend requested

  return axios.post('/api/friend/add', { user_id, currentUser_id }).then(
    response => dispatch(loadCurrentUserSuccess(response.data)), // load current user successful
    error => {
      dispatch(removeFriendRequest(user_id))
      dispatch({ type: ActionTypes.ADD_FRIEND_FAIL, error: 'Failed to add friend' }) // add friend failed
    }
  )
}

// load other user
export const removeFriend = (user_id, currentUser_id) => (dispatch) => {
  dispatch(removeFriendRequest(user_id)) // remove friend

  return axios.post('/api/friend/remove', { user_id, currentUser_id }).then(
    response => dispatch(loadCurrentUserSuccess(response.data)), // load current user successful
    error => {
      dispatch(addFriendRequest(user_id))
      dispatch({ type: ActionTypes.REMOVE_FRIEND_FAIL, error: 'Failed to remove friend' }) // remove friend failed
    }
  )
}

// set search string
export const setSearch = (search) => ({ type: ActionTypes.SET_SEARCH, search })

// set search string
export const setSortBy = (sortBy) => ({ type: ActionTypes.SET_SORTBY, sortBy })
