import initialState from './initialState'
import ActionTypes from '../actions/actionTypes'

const usersReducer = (state = initialState.users, action) => {
  switch(action.type) {

    case ActionTypes.LOGOUT:
      return initialState.users

    case ActionTypes.LOAD_USERS_SUCCESS:
      return action.users

    case ActionTypes.SIGNUP_SUCCESS:
      return [ ...state, action.user ]

    default:
      return state
  }
}

export default usersReducer
