import PropTypes from 'prop-types';
import React from 'react'

const MatchUpRow = ({ row, onClickUser }) => {

  const handleClickUser = () => onClickUser ? onClickUser({_id: row._id, name: row.name}) : null

  return (
    <div className="match-up-row">
        <div className="match-up-col" onClick={handleClickUser}>{row.name}</div>
        <div className="match-up-col center">{row.wins}</div>
        <div className="match-up-col center">{row.best}</div>
        <div className="match-up-col center">{row.avg}</div>
    </div>
  )
}

MatchUpRow.propTypes = {
  row: PropTypes.object.isRequired,
  onClickUser: PropTypes.func
}

export default MatchUpRow
