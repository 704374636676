import PropTypes from 'prop-types';
import React from 'react'

const SettingsButton = ({ name, onClick }) => (<div className="settings-button" onClick={onClick}>{name}</div>)

SettingsButton.propTypes = {
  name: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired
}

export default SettingsButton
