import PropTypes from 'prop-types';
import React from 'react'
import PlayerScore from '../components/PlayerScore'

const ScoreContainer = ({ players }) => (
  <div className="other-players">
    {players.map((u, index) => <PlayerScore key={index} player={u} />)}
  </div>
)

ScoreContainer.propTypes = {
  players: PropTypes.array.isRequired
}

export default ScoreContainer
