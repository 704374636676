import initialState from './initialState'
import ActionTypes from '../actions/actionTypes'

const gameReducer = (state = initialState.game, action) => {
  switch(action.type) {

    case ActionTypes.LOAD_GAME:
      return action.game

    case ActionTypes.FINISH_GAME_REQUEST:
      return action.game

    case ActionTypes.FINISH_GAME_SUCCESS:
      return action.game

    case ActionTypes.FINISH_OTHER_GAME_REQUEST:
      return action.game

    case ActionTypes.FINISH_OTHER_GAME_SUCCESS:
      return action.game

    case ActionTypes.DELETE_GAME_REQUEST:
      return initialState.game

    case ActionTypes.SAVE_GAME_REQUEST:
      return action.game

    default:
      return state
  }
}

export default gameReducer
