export default {

	// set error action
	SET_ERROR: "SET_ERROR",

	// change login attribute types
	CHANGE_NAME: "CHANGE_NAME",
	CHANGE_PASSWORD: "CHANGE_PASSWORD",
	SWITCH_LOGIN: "SWITCH_LOGIN",

	// login action types
	LOGIN_REQUEST: "LOGIN_REQUEST",
	LOGIN_SUCCESS: "LOGIN_SUCCESS",
	LOGIN_FAIL: "LOGIN_FAIL",

	// signup action types
	SIGNUP_REQUEST: "SIGNUP_REQUEST",
	SIGNUP_SUCCESS: "SIGNUP_SUCCESS",
	SIGNUP_FAIL: "SIGNUP_FAILED",

	// logout action
	LOGOUT: "LOGOUT",

	// load current user action types
	LOAD_CURRENT_USER_REQUEST: "LOAD_CURRENT_USER_REQUEST",
	LOAD_CURRENT_USER_SUCCESS: "LOAD_CURRENT_USER_SUCCESS",
	LOAD_CURRENT_USER_FAIL: "LOAD_CURRENT_USER_FAIL",

	// load users types
	LOAD_USERS_REQUEST: "LOAD_USERS_REQUEST",
	LOAD_USERS_SUCCESS: "LOAD_USERS_SUCCESS",
	LOAD_USERS_FAIL: "LOAD_USERS_FAIL",

	// load games types
	LOAD_GAMES_REQUEST: "LOAD_GAMES_REQUEST",
	LOAD_GAMES_SUCCESS: "LOAD_GAMES_SUCCESS",
	LOAD_GAMES_FAIL: "LOAD_GAMES_FAIL",

	// load game
	LOAD_GAME: "LOAD_GAME",

	// load other user
	LOAD_OTHER_USER: "LOAD_OTHER_USER",

	// remove other user
	REMOVE_OTHER_USER: "REMOVE_OTHER_USER",

	// load other games actions
	LOAD_OTHER_GAMES_SUCCESS: "LOAD_OTHER_GAMES_SUCCESS",
	LOAD_OTHER_GAMES_FAIL: "LOAD_OTHER_GAMES_FAIL",

	// load combined games actions
	LOAD_COMBINED_GAMES_REQUEST: "LOAD_COMBINED_GAMES_REQUEST",
	LOAD_COMBINED_GAMES_SUCCESS: "LOAD_COMBINED_GAMES_SUCCESS",
	LOAD_COMBINED_GAMES_FAIL: "LOAD_COMBINED_GAMES_FAIL",

	// load scoreboard games actions
	LOAD_SCOREBOARD_GAMES_REQUEST: "LOAD_SCOREBOARD_GAMES_REQUEST",
	LOAD_SCOREBOARD_GAMES_SUCCESS: "LOAD_SCOREBOARD_GAMES_SUCCESS",
	LOAD_SCOREBOARD_GAMES_FAIL: "LOAD_SCOREBOARD_GAMES_FAIL",

	REMOVE_SCOREBOARD: "REMOVE_SCOREBOARD",

	// delete game types
	DELETE_GAME_REQUEST: "DELETE_GAME_REQUEST",
	DELETE_GAME_SUCCESS: "DELETE_GAME_SUCCESS",
	DELETE_GAME_FAIL: "DELETE_GAME_FAIL",

	// edit game types
	EDIT_GAME: "EDIT_GAME",
	CANCEL_EDIT_GAME: "CANCEL_EDIT_GAME",
	EDIT_SHOT: "EDIT_SHOT",

	// save game types
	SAVE_GAME_REQUEST: "SAVE_GAME_REQUEST",
	SAVE_GAME_FAIL: "SAVE_GAME_FAIL",

	// new game actions
	START_NEW_GAME: "START_NEW_GAME",
	SAVE_PLAYING: "SAVE_PLAYING",
	CANCEL_PLAYING: "CANCEL_PLAYING",
	START_GAME: "START_GAME",
	CHANGE_SHOT: "CHANGE_SHOT",
	CHANGE_TURN: "CHANGE_TURN",
	START_OT: "START_OT",
	REMOVE_OT: "REMOVE_OT",
	FINISH_GAME_REQUEST: "FINISH_GAME_REQUEST",
	FINISH_GAME_SUCCESS: "FINISH_GAME_SUCCESS",
	FINISH_OTHER_GAME_REQUEST: "FINISH_OTHER_GAME_REQUEST",
	FINISH_OTHER_GAME_SUCCESS: "FINISH_OTHER_GAME_SUCCESS",

	// friend actions
	ADD_FRIENDS: "ADD_FRIENDS",
	ADD_FRIEND_REQUEST: "ADD_FRIEND_REQUEST",
	ADD_FRIEND_FAIL: "ADD_FRIEND_FAIL",
	REMOVE_FRIEND_REQUEST: "REMOVE_FRIEND_REQUEST",
	REMOVE_FRIEND_FAIL: "REMOVE_FRIEND_FAIL",

	// start rematch
	START_REMATCH: "START_REMATCH",

	// set search string
	SET_SEARCH: "SET_SEARCH",

	// set sort by
	SET_SORTBY: "SET_SORTBY"

}
