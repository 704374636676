import initialState from './initialState'
import ActionTypes from '../actions/actionTypes'

const combinedGamesReducer = (state = initialState.combinedGames, action) => {
  switch(action.type) {

    case ActionTypes.LOAD_COMBINED_GAMES_REQUEST:
      return initialState.combinedGames

    case ActionTypes.LOAD_COMBINED_GAMES_SUCCESS:
      return action.games

    case ActionTypes.FINISH_GAME_SUCCESS:
      return [...state, action.game]

    case ActionTypes.FINISH_OTHER_GAME_SUCCESS:
      return [...state, action.game]

    case ActionTypes.LOGOUT:
      return initialState.combinedGames

    default:
      return state
  }
}

export default combinedGamesReducer
