import initialState from './initialState'
import ActionTypes from '../actions/actionTypes'

const playingReducer = (state = initialState.playing, action) => {
  switch(action.type) {

    case ActionTypes.START_NEW_GAME:
      return [action.user]

    case ActionTypes.SAVE_PLAYING:
      return action.users

    case ActionTypes.CANCEL_PLAYING:
      return initialState.playing

    default:
      return state
  }
}

export default playingReducer
