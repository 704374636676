import React, { Component } from 'react'
import { withRouter } from 'react-router-dom';
import Header from '../components/common/Header'
import Footer from '../components/common/Footer'

class HowToPlayPage extends Component {
  constructor(props) {
    super(props)

    this.handleBack = this.handleBack.bind(this)
  }
  
  handleBack() {
    this.props.history.goBack()
  }

  render() {
    return (
      <div className="page">
        <Header name="How to play" />
        <div className="container">
          <ol type="1" className="how-list">
            <li>you need a mini basketball, a mini basketball hoop, and at least one friend</li>
            <li>you will each take ten shots, alternating the shooter every five shots. one player rebounds while the other shoots. the goal is to make all ten shots aka 10 for 10</li>
            <li>pick a spot to shoot from. preferably the shot will be very hard, yet makable</li>
            <li>for the first round of five of the first game, each player starts with a warm up shot. if made, you count that as one for one and you have four shots left. if missed, you are zero for zero and your next shot is your first shot</li>
            <li>alternate shooting in rounds of 5 and rebounding for each other. if you have more than one player, the second shooter rebounds for the first, the third rebounds for the second, ..., the first rebounds for the last</li>
            <li>if there is a tie after 10 shots, an additional overtime of 5 shots is played until there is a winner</li>
            <li>you can continue playing as many games as you want and if you want to add more people, the new player should rebound for the previous last shooter, and the first shooter will rebound for the new player</li>
            <li>have fun!</li>
          </ol>
        </div>
        <Footer onClick={this.handleBack} name="Back"/>
      </div>
    )
  }
}

export default withRouter(HowToPlayPage)
