import PropTypes from 'prop-types';
import React from 'react'
import Shots from './Shots'

const Player = ({ player, index, onChange, onClickUser }) => {
  const handleClickUser = () => onClickUser ? onClickUser(player) : null

  return (
    <div className="player-result">
      <div className="player-title">
        <div className="player-name" onClick={handleClickUser}>{player.name}</div>
        <div className="player-score">{player.score}</div>
      </div>
      <div className="player-shots">
        <Shots shots={player.shots} index={index} onChange={onChange} />
      </div>
    </div>
  )
}

Player.propTypes = {
  player: PropTypes.object.isRequired,
  index: PropTypes.number,
  onClickUser: PropTypes.func,
  onChange: PropTypes.func
}

export default Player
