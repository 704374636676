import PropTypes from 'prop-types';
import React from 'react'

const Shooter = ({ shooter }) => (
  <div className="shooter">
    <div className="shooter-name">{shooter.name}</div>
    <div className="shooter-score">{shooter.score}</div>
  </div>
)

Shooter.propTypes = {
  shooter: PropTypes.object.isRequired
}

export default Shooter
