import PropTypes from 'prop-types';
import React from 'react'
import Plus from 'react-icons/lib/io/plus'
import Minus from 'react-icons/lib/io/close'

const FriendRow = ({ user, onClickUser, onClickAdd, friend }) => (
  <div className="playing-row" key={user._id}>
    <div className="playing-name" onClick={() => onClickUser(user)}>{user.name}</div>
    <div className="friend-button" onClick={() => onClickAdd(user)}>{friend ? <Minus className="not-friend" /> : <Plus className="friend" />}</div>
  </div>
)

FriendRow.propTypes = {
  user: PropTypes.object.isRequired,
  onClickUser: PropTypes.func.isRequired,
  onClickAdd: PropTypes.func.isRequired,
  friend: PropTypes.bool.isRequired
}

export default FriendRow
