import PropTypes from 'prop-types';
import React from 'react'

const PlayerScore = ({ player }) => (
  <div className="other-player">
    <div className="other-player-name">{player.name}</div>
    <div className="other-player-score">{player.score}</div>
  </div>
)

PlayerScore.propTypes = {
  player: PropTypes.object.isRequired
}

export default PlayerScore
