import PropTypes from 'prop-types';
import React, { Component } from 'react'
import Plus from 'react-icons/lib/io/android-add'
import Minus from 'react-icons/lib/io/android-close'
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux'
import { loadGame } from '../actions/gameActions'
import { removeOtherUser, addFriend, removeFriend } from '../actions/userActions'
import { gamesToStats } from '../selectors/selectors'
import Header from '../components/common/Header'
import HeaderButtonRight from '../components/common/HeaderButtonRight'
import StatsContainer from '../containers/StatsContainer'
import Footer from '../components/common/Footer'

class OtherUserPage extends Component {
  constructor(props) {
    super(props)

    this.handleLoadGames = this.handleLoadGames.bind(this)
    this.handleLoadGame = this.handleLoadGame.bind(this)
    this.handleAddFriend = this.handleAddFriend.bind(this)
    this.handleRemoveFriend = this.handleRemoveFriend.bind(this)
    this.handleBack = this.handleBack.bind(this)
  }

  componentWillMount() {
    if (this.props.otherUser.name === '') {
      this.props.history.push('/')
    }
  }

  handleLoadGames() {
    this.props.history.push('/otherGames')
  }

  handleLoadGame(game) {
    const { otherGames, loadGame } = this.props

    game = otherGames.find(g => g._id === game._id)
    loadGame(game)
    this.props.history.push('/game')
  }

  handleAddFriend(evt) {
    evt.preventDefault()
    const { otherUser, currentUser, addFriend } = this.props

    addFriend(otherUser._id, currentUser._id)
  }

  handleRemoveFriend(evt) {
    evt.preventDefault()
    const { otherUser, currentUser, removeFriend } = this.props

    removeFriend(otherUser._id, currentUser._id)
  }

  handleBack() {
    this.props.removeOtherUser()
    this.props.history.goBack()
  }

  render() {
    const { currentUser, otherUser, stats, loading } = this.props

    let handleFriend, friend
    if (currentUser.friends.findIndex(friend => friend === otherUser._id) !== -1) {
      handleFriend = this.handleRemoveFriend
      friend = true
    } else {
      handleFriend = this.handleAddFriend
      friend = false
    }

    const me = currentUser._id === otherUser._id

    return (
      <div>
        <Header name={otherUser.name} />
        {!me && <HeaderButtonRight onClick={handleFriend}>{friend ? <Minus /> : <Plus />}</HeaderButtonRight>}
        <StatsContainer
          games={stats}
          currentUser={currentUser}
          user={otherUser}
          clickGame={this.handleLoadGame}
          clickGames={this.handleLoadGames}
          loading={loading}
        />
        <Footer onClick={this.handleBack} name="Back" />
      </div>
    )
  }
}

OtherUserPage.propTypes = {
  currentUser: PropTypes.object.isRequired,
  otherUser: PropTypes.object.isRequired,
  otherGames: PropTypes.array.isRequired
}

const mapStateToProps = (state) => {
  const { currentUser, otherUser, otherGames, loading } = state

  const stats = gamesToStats(otherGames, otherUser._id)

  return {
    currentUser,
    otherUser,
    stats,
    otherGames,
    loading
  }
}

OtherUserPage = connect(
  mapStateToProps,
  { loadGame, removeOtherUser, addFriend, removeFriend }
)(OtherUserPage)

export default withRouter(OtherUserPage)
