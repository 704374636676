import PropTypes from 'prop-types';
import React from 'react'
import SettingsButton from '../components/SettingsButton'

const SettingsContainer = ({ onClickFriends, onClickPlay, onClickLogout }) => (
  <div className="container">
    <SettingsButton onClick={onClickFriends} name="Friends" />
    <SettingsButton onClick={onClickPlay} name="How to play" />
    <SettingsButton onClick={onClickLogout} name="Logout" />
  </div>
)

SettingsContainer.propTypes = {
  onClickFriends: PropTypes.func.isRequired,
  onClickPlay: PropTypes.func.isRequired,
  onClickLogout: PropTypes.func.isRequired
}

export default SettingsContainer
