import PropTypes from 'prop-types';
import React from 'react'
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux'
import { changeShot, changeTurn, startOT, removeOT, finishGame } from '../actions/newGameActions'
import Header from '../components/common/Header'
import ShooterContainer from '../containers/ShooterContainer'
import Rebounder from '../components/Rebounder'
import ScoreContainer from '../containers/ScoreContainer'
import ThirdFooter from '../components/common/ThirdFooter'
import SecondFooter from '../components/common/SecondFooter'
import Footer from '../components/common/Footer'

class PlayGamePage extends React.Component {
  constructor(props) {
    super(props)

    this.checkFinish = this.checkFinish.bind(this)
    this.handleChangeShot = this.handleChangeShot.bind(this)
    this.handleNextTurn = this.handleNextTurn.bind(this)
    this.handleLastTurn = this.handleLastTurn.bind(this)
    this.handleOT = this.handleOT.bind(this)
    this.handleFinish = this.handleFinish.bind(this)
    this.handleBack = this.handleBack.bind(this)
  }

  componentWillMount() {
    if (this.props.players.length === 0) {
      this.props.history.push('/')
    }
	}

  checkFinish() {
    let best = 0;
    let over = true;
    this.props.players.forEach(player => {
      if (player.score > best) {
        over = true
        best = player.score
      } else if (player.score === best) {
        over = false
      }
    })
    return over;
  }

  handleChangeShot(index, made) {
    this.props.changeShot(this.props.turn, index, made)
  }

  handleNextTurn() {
    let { turn, changeTurn } = this.props
    changeTurn(turn + 1)
  }

  handleLastTurn() {
    const { turn, players, removeOT, changeTurn } = this.props
    const playersLength = players.length
    turn >= playersLength * 2 && turn % playersLength === 0 ? removeOT(players) : changeTurn(turn - 1)
  }

  handleOT() {
    this.props.startOT(this.props.players)
  }

  handleFinish() {
    const { currentUser, players, finishGame } = this.props
    finishGame(players, currentUser._id)
    this.props.history.push('/gameOver')
  }

  handleBack() {
    this.props.history.push('/order')
  }

  render() {
    const { players, turn } = this.props
    const playersLength = players.length

    if (playersLength) {
      const shooter = players[turn % playersLength]
      const rebounder = players[(turn + 1) % playersLength]
      const round = Math.floor(turn / playersLength)
      const shots = shooter.shots[round]

      const shotsLength = shooter.shots.length

      let otherShots = []
      for (let i = 0; i < round; i++) {
        otherShots.push(shooter.shots[i])
      }

      let nextButtonLabel, nextButtonHandle, lastButtonLabel, lastButtonHandle

      let backButtonLabel = 'Back'
      let backButtonHandle = turn === 0 ? this.handleBack : this.handleLastTurn

      if (turn === playersLength * shotsLength - 1 && this.checkFinish()) {
        nextButtonLabel = 'Finish'
        nextButtonHandle = this.handleFinish
      } else if (turn === playersLength * shotsLength - 1 && !this.checkFinish()) {
        nextButtonLabel = shotsLength === 2 ? 'Start OT' : 'Start OT #' + (shotsLength - 1).toString()
        nextButtonHandle = this.handleOT
        backButtonLabel = 'Tie'
        backButtonHandle = this.handleFinish
        lastButtonLabel = 'Back'
        lastButtonHandle = this.handleLastTurn
      } else {
        nextButtonLabel = 'Next'
        nextButtonHandle = this.handleNextTurn
      }

      const roundLabel = shotsLength === 2 ? 'Round ' + (round + 1).toString() : 'OT #' + (shotsLength - 2).toString()

      return (
        <div className="page">
          <Header name={roundLabel} />
          <div className="page-container three-footer" >
            <ShooterContainer shooter={shooter} shots={shots} otherShots={otherShots} onChange={this.handleChangeShot} />
            <Rebounder rebounder={rebounder} />
            <ScoreContainer players={players} />
          </div>
          { lastButtonLabel && <ThirdFooter onClick={lastButtonHandle} name={lastButtonLabel} /> }
          <SecondFooter onClick={backButtonHandle} name={backButtonLabel} />
          <Footer onClick={nextButtonHandle} name={nextButtonLabel} />
        </div>
      )
    } else {
      return (
        <div className="page">
          <div className="main two-footer">
          </div>
        </div>
      )
    }
  }
}

PlayGamePage.propTypes = {
  turn: PropTypes.number.isRequired,
  players: PropTypes.array.isRequired,
  changeShot: PropTypes.func.isRequired,
  changeTurn: PropTypes.func.isRequired,
  startOT: PropTypes.func.isRequired,
  removeOT: PropTypes.func.isRequired,
  finishGame: PropTypes.func.isRequired
}

const mapStateToProps = (state) => {
  const { currentUser, newGame } = state
  const { turn, players } = newGame

  return {
    currentUser,
    turn,
    players
  }
}

PlayGamePage = connect(
  mapStateToProps,
  { changeShot, changeTurn, startOT, removeOT, finishGame }
)(PlayGamePage)

export default withRouter(PlayGamePage)
