import PropTypes from 'prop-types';
import React, { Component } from 'react'
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux'
import { loadOtherUser, addFriend, removeFriend, setSearch } from '../actions/userActions'
import Header from '../components/common/Header'
import SearchBar from '../components/common/SearchBar'
import FriendsContainer from '../containers/FriendsContainer'
import Footer from '../components/common/Footer'

class FriendsPage extends Component {
  constructor(props) {
    super(props)

    this.handleSearchChange = this.handleSearchChange.bind(this)
    this.handleLoadUser = this.handleLoadUser.bind(this)
    this.handleAddFriend = this.handleAddFriend.bind(this)
    this.handleRemoveFriend = this.handleRemoveFriend.bind(this)
    this.handleBack = this.handleBack.bind(this)
    this.handleClearSearch = this.handleClearSearch.bind(this)
  }

  handleLoadUser(player) {
    const { setSearch, loadOtherUser } = this.props
    setSearch('')
    loadOtherUser(player)
    this.props.history.push('/other')
  }

  handleAddFriend(player) {
    const { addFriend, currentUser } = this.props
    addFriend(player._id, currentUser._id)
  }

  handleRemoveFriend(player) {
    const { removeFriend, currentUser } = this.props
    removeFriend(player._id, currentUser._id)
  }

  handleSearchChange(evt) {
    this.props.setSearch(evt.target.value)
  }

  handleClearSearch(evt) {
    evt.preventDefault()
    this.props.setSearch('')
  }

  handleBack() {
    this.props.setSearch('')
    this.props.history.push('/')
  }

  render() {
    let { currentUser, users, loading, search } = this.props

    const friends = []
    const others = []

    users = users.filter(user => user.name.indexOf(search) >= 0)

    users.forEach(user => {
      if (user._id === currentUser._id) {
        return false
      }
      currentUser.friends.includes(user._id) ? friends.push(user) : others.push(user)
    })

    return (
      <div className="page">
        <Header name='Friends' />
        <SearchBar search={search} onChange={this.handleSearchChange} onClear={this.handleClearSearch} />
        <FriendsContainer
          friends={friends}
          others={others}
          onLoadUser={this.handleLoadUser}
          onAddFriend={this.handleAddFriend}
          onRemoveFriend={this.handleRemoveFriend}
          loading={loading}
        />
        <Footer onClick={this.handleBack} name="Back"/>
      </div>
    )
  }
}

FriendsPage.propTypes = {
  currentUser: PropTypes.object.isRequired,
  users: PropTypes.array.isRequired,
  loading: PropTypes.bool,
  search: PropTypes.string
}

const mapStateToProps = (state) => {
  const { currentUser, users, loading, search } = state

  return { currentUser, users, loading, search }
}

FriendsPage = connect(
  mapStateToProps,
  { loadOtherUser, addFriend, removeFriend, setSearch }
)(FriendsPage)

export default withRouter(FriendsPage)
