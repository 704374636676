import PropTypes from 'prop-types';
import React from 'react';
import ScoreboardRow from '../components/ScoreboardRow';

const ScoreboardContainer = ({ friends, users, stats, onClickUser, loading, sortBy, onChange }) => {
  let row = {
    name: '',
    games: 'Games',
    wins: 'Wins',
    best: 'Best',
    shots: 'Shots',
    avg: 'Avg'
  };

  const players = friends.map(friend => {
    let stat = stats[friend];

    const user = users.find(user => user._id === friend);

    if (!user) return null;

    return {
      _id: friend,
      name: user.name,
      games: stat.gamesLength,
      wins: stat.wins,
      best: stat.best,
      shots: stat.score,
      avg: stat.avg
    };
  });

  let rows = players
    .filter(player => player)
    .sort((first, second) => second[sortBy] - first[sortBy]);

  rows = rows.map(row => <ScoreboardRow key={row._id} row={row} onClickUser={onClickUser} />);

  return (
    <div className="container">
      <ScoreboardRow row={row} onClick={onChange} />
      {loading || users.length === 0 ? <div>Loading...</div> : rows}
    </div>
  );
};

ScoreboardContainer.propTypes = {
  friends: PropTypes.array.isRequired,
  users: PropTypes.array,
  stats: PropTypes.object.isRequired,
  onClickUser: PropTypes.func,
  loading: PropTypes.bool,
  sortBy: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired
};

export default ScoreboardContainer;
