import PropTypes from 'prop-types';
import React from 'react'
import Make from 'react-icons/lib/io/ios-basketball'
import Miss from 'react-icons/lib/io/ios-basketball-outline'

const ShooterShots = ({ shots, onChange }) => (
  <div className="shooter-shots">
    {shots.map((shot, index) => (
      <div className="shot" key={index} onClick={() => onChange(index, shot)}>
        <input className="shot-checkbox" type="checkbox" checked={shot} readOnly/>
        <label className="shot-label">{shot ? <Make className="made-shot" size={60} /> : <Miss size={60} />}</label>
      </div>
    ))}
  </div>
)

ShooterShots.propTypes = {
  shots: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired
}

export default ShooterShots
