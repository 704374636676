export default {
  loading: false,   // loading across app
  error: '',        // error across app
  loginState: {      // login page state
    login: true,
    name: '',
    password: '',
  },
  search: '',       // search input throughout app
  sortBy: 'games',       // lastWeek, lastMonth, lastYear, games, shot%, best5, best10, etc.
  currentUser: {    // current user state
    _id: '',
    name: '',
    friends: []     // friends _id's
  },
  games: [],        // current user's games {_id, players: {_id, name, shots, score}, winner}
  game: {           // current game clicked
    _id: '',
    players: [],    // {}
    winner: ''
  },
  editGame: {       // current game we are editing
    _id: '',
    players: [],    // {_id, name, shots, score}
    winner: ''
  },
  playing: [],        // users that are selected to play in game {_id, name}
  newGame: {        // new game state
    turn: 0,
    players: []     // {_id, name, shots, score}
  },
  users: [],        // all users {_id, name}
  otherUser: {      // user loaded
    _id: '',
    name: ''
  },
  otherGames: [],   // games from loaded user {_id, players: {_id, name, shots, score}, winner}
  combinedGames: [], // games for all users we are comparing {_id, players: {_id, name, shots, score}, winner}
  scoreboardGames: []
}
