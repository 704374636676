import initialState from './initialState'
import ActionTypes from '../actions/actionTypes'

const otherGamesReducer = (state = initialState.otherGames, action) => {
  switch(action.type) {

    case ActionTypes.LOAD_OTHER_USER:
      return initialState.otherGames

    case ActionTypes.LOAD_OTHER_GAMES_SUCCESS:
      return action.games

    case ActionTypes.DELETE_GAME_REQUEST:
      return state.filter(game => {
        return game._id !== action.game_id
      })

    case ActionTypes.SAVE_GAME_REQUEST:
      let saveGame = action.game
      let games = state
      let index = games.findIndex(game => game._id === saveGame._id)
      if (index === -1) {
        return games
      } else {
        return [ ...games.slice(0, index), saveGame, ...games.slice(index + 1) ]
      }

    case ActionTypes.REMOVE_OTHER_USER:
      return initialState.otherGames

    default:
      return state
  }
}

export default otherGamesReducer
