import PropTypes from 'prop-types';
import React from 'react'
import CategoryRow from '../components/common/CategoryRow'
import SearchBar from '../components/common/SearchBar'
import PlayingRow from '../components/PlayingRow'

const PlayingContainer = ({ user, friends, users, playing, error, loading, search, onClick, onChange, onClear }) => {
  // creates row for given user
  const createRow = (user) => <PlayingRow
                              key={user._id}
                              user={user}
                              onClick={onClick}
                            />

  // checks if user is in players array
  const checkPlaying = (players, user) => players.some(player => player._id === user._id)

  const checkedCurrent = { ...user, checked: checkPlaying(playing, user) } // current user with checked
  const checkedFriends = friends.reduce((total, friend) => total.concat({ ...friend, checked: checkPlaying(playing, friend) }), []) // friends with checked
  const checkedUsers = users.reduce((total, user) => total.concat({ ...user, checked: checkPlaying(playing, user) }), []) // users with checked

  const friendsLength = friends.length > 0
  const usersLength = users.length > 0

  return (
    <div className="container two-footer">
      { error && <div className="error">{error}</div> }
      <CategoryRow name="Me" />
      { createRow(checkedCurrent) }
      { friendsLength && <CategoryRow name="Friends" /> }
      { checkedFriends.map(friend => createRow(friend)) }
      <CategoryRow name="Other" />
      <SearchBar search={search} onChange={onChange} />
      { loading && !friendsLength && !usersLength && <div>Loading...</div> }
      { checkedUsers.map(user => createRow(user)) }
    </div>
  )
}

PlayingContainer.propTypes = {
  user: PropTypes.object.isRequired,
  friends: PropTypes.array.isRequired,
  users: PropTypes.array.isRequired,
  playing: PropTypes.array,
  onClick: PropTypes.func.isRequired,
  error: PropTypes.string,
  loading: PropTypes.bool,
  search: PropTypes.string,
  onChange: PropTypes.func.isRequired
}

export default PlayingContainer
