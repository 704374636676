import PropTypes from 'prop-types';
import React from 'react'

const CategoryRow = ({ name }) => (<div className="category-row">{name}</div>)

CategoryRow.propTypes = {
  name: PropTypes.string
}

export default CategoryRow
