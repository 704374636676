import PropTypes from 'prop-types';
import React from 'react'

const HeaderButtonLeft = ({ children, onClick }) => (<button className="header-button header-button-left" onClick={onClick}>{children}</button>)

HeaderButtonLeft.propTypes = {
  onClick: PropTypes.func.isRequired
}

export default HeaderButtonLeft
