import PropTypes from 'prop-types';
import React from 'react'
import Stat from '../components/Stat'
import BarChart from '../components/common/BarChart'
import LineChart from '../components/common/LineChart'
import { calcGameStats, calcShotStats, calcGameOf10Stats } from '../selectors/selectors'

const StatsContainer = ({ currentUser, user, games, clickGame, clickGames, loading }) => {
  const gamesLength = games ? games.length > 0 : 0;
  if (!gamesLength && loading) { // no games and loading
    return (
      <div className="container">
        <div>Loading...</div>
      </div>
    )
  } else if (!gamesLength) { // no games and not loading
    return (
      <div className="container">
        <div>Welcome</div>
      </div>
    )
  }

  user = user ? user : currentUser

  const totalGames = games.length
  const lastGame = games[totalGames - 1]
  const name = user.name

  const gameStats = calcGameStats(games, name)
  const shotStats = calcShotStats(games)
  const gameOf10Stats = calcGameOf10Stats(games)

  const formPercent = (num) => Math.round(num * 100)
  const formNum = (num) => Math.round(num * 100) / 100

  const lastGameScore = lastGame.score

  const bestGame = gameStats.bestGame
  const bestScore = bestGame.score
  const longestGame = gameStats.longestGame
  const mostRounds = longestGame.rounds
  const totalShots = gameStats.totalShots
  const totalRounds = gameStats.totalRounds
  const totalOTs = gameStats.totalOTs
  // const percentOT = formPercent(totalOTs / totalGames)
  const totalOTRounds = gameStats.totalOTRounds
  const totalWins = gameStats.totalWins
  // const winPercent = formPercent(totalWins / totalGames)
  const totalTies = gameStats.totalTies
  // const tiePercent = formPercent(totalTies / totalGames)
  const totalLoses = totalGames - totalWins - totalTies
  let record = totalWins + " - " + totalLoses
  if (totalTies) {
    record += " - " + totalTies
  }

  const totalMakes = shotStats.totalMakes
  const averagePerGame = formNum(totalMakes / totalGames)
  const averagePerRound = formNum(totalMakes / totalRounds)
  const percentMakes = formPercent(totalMakes / totalShots)
  const totalOTMakes = shotStats.totalOTMakes
  const percentOTMakes = totalOTRounds ? formPercent(totalOTMakes / (totalOTRounds * 5)) : 0

  const shotMakes = shotStats.shotMakes
  let shotOf5Percent = Array(5).fill(0)
  for (let i = 0; i < 5; i++) {
    shotOf5Percent[i] = formPercent(shotMakes[i] / totalRounds)
  }
  const bestOf5 = shotStats.bestOf5
  const bestOf5Score = bestOf5.bestOf5
  const timesOf5 = shotStats.timesOf5
  let percentFor5 = Array(bestOf5).fill(0)
  for (let i = 0; i <= bestOf5; i++) {
    percentFor5[i] = formPercent(timesOf5[i] / totalRounds)
  }
  const bestStreakGame = shotStats.bestStreak
  const bestStreak = bestStreakGame.streak

  const bestGameOf10 = gameOf10Stats.bestGame
  const bestGameOf10Score = bestGameOf10.currentScore
  const totalMakesOf10 = gameOf10Stats.totalMakes
  const regGamePercent = formPercent(totalMakesOf10 / (totalGames * 10))
  const averagePer10 = formNum(totalMakesOf10 / totalGames)
  const front5Makes = gameOf10Stats.front5Makes
  const frontPercent = formPercent(front5Makes / (totalGames * 5))
  const back5Makes = gameOf10Stats.back5Makes
  const backPercent = formPercent(back5Makes / (totalGames * 5))
  const shotMakesOf10 = gameOf10Stats.shotMakes
  let shotOf10Percent = Array(10).fill(0)
  for (let i = 0; i < 10; i++) {
    shotOf10Percent[i] = formPercent(shotMakesOf10[i] / totalGames)
  }
  const timesOf10 = gameOf10Stats.timesOf10
  let percentFor10 = Array(bestGameOf10Score).fill(0)
  for (let i = 0; i <= bestOf5; i++) {
    percentFor10[i] = formPercent(timesOf10[i] / totalGames)
  }

  const shotPercentData = shotOf10Percent.reduce((sum, percent, currentIndex) => sum.concat({ name: (currentIndex + 1).toString(), percent}), [])
  const shot5PercentData = shotOf5Percent.reduce((sum, percent, currentIndex) => sum.concat({ name: (currentIndex + 1).toString(), percent}), [])

  return (
    <div className="container">
      <div className="row">
        <Stat number={record} name="" />
      </div>
      <div className="row">
        <Stat number={totalGames} name="Games" onClick={clickGames} />
        <Stat number={bestScore} name="Best" game={bestGame} onClick={clickGame} />
        <Stat number={averagePerGame} name="Avg" />
      </div>
      <div className="chart-row">
        <div className="chart">
          <LineChart data={games} xKey="ind" yKey="score" />
        </div>
      </div>
      <div className="chart-row">
        <div className="chart">
          <BarChart data={shotPercentData} xKey="name" yKey="percent" />
        </div>
      </div>
      <div className="chart-row">
        <div className="chart">
          <BarChart data={shot5PercentData} xKey="name" yKey="percent" />
        </div>
      </div>
      <div className="row">
        <Stat number={lastGameScore} name="Last" game={lastGame} onClick={clickGame} />
        <Stat number={totalMakes} name="Total" />
        <Stat number={bestGameOf10Score} name="Best for 10" game={bestGameOf10} onClick={clickGame} />
      </div>
      <div className="row">
        <Stat number={averagePer10} name="Avg for 10" />
        <Stat number={bestOf5Score} name="Best for 5" game={bestOf5} onClick={clickGame} />
        <Stat number={bestStreak} name="Long streak" game={bestStreakGame} onClick={clickGame} />
      </div>
      <div className="row">
        <Stat number={averagePerRound} name="Avg per round" />
        <Stat number={frontPercent} name="Front 5" percent />
        <Stat number={backPercent} name="Back 5" percent />
      </div>
      <div className="row">
        <Stat number={totalOTs} name="OTs" />
        <Stat number={totalOTMakes} name="OT makes" />
        <Stat number={mostRounds} name="Longest game" game={longestGame} onClick={clickGame} />
      </div>
      <div className="row">
        <Stat number={percentMakes} name="Made" percent />
        <Stat number={regGamePercent} name="Reg game" percent />
        <Stat number={percentOTMakes} name="OT" percent />
      </div>
    </div>
  )
}

StatsContainer.propTypes = {
  currentUser: PropTypes.object.isRequired,
  user: PropTypes.object,
  games: PropTypes.array.isRequired,
  clickGame: PropTypes.func.isRequired,
  clickGames: PropTypes.func.isRequired,
  loading: PropTypes.bool
}

export default StatsContainer
