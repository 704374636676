import initialState from './initialState'
import ActionTypes from '../actions/actionTypes'

const errorReducer = (state = initialState.error, action) => {
  switch(action.type) {

    case ActionTypes.SET_ERROR:
      return action.error

    case ActionTypes.CHANGE_NAME:
      return ''

    case ActionTypes.CHANGE_PASSWORD:
      return ''

    case ActionTypes.SWITCH_LOGIN:
      return ''

    case ActionTypes.LOGIN_REQUEST:
      return ''

    case ActionTypes.LOGIN_FAIL:
      return action.error

    case ActionTypes.SIGNUP_REQUEST:
      return ''

    case ActionTypes.SIGNUP_FAIL:
      return action.error

    case ActionTypes.LOGOUT:
      return ''

    case ActionTypes.LOAD_CURRENT_USER_FAIL:
      return action.error

    case ActionTypes.LOAD_GAMES_REQUEST:
      return ''

    case ActionTypes.LOAD_GAMES_FAIL:
      return action.error

    case ActionTypes.LOAD_OTHER_USER:
      return ''

    case ActionTypes.LOAD_OTHER_GAMES_FAIL:
      return action.error

    case ActionTypes.LOAD_COMBINED_GAMES_REQUEST:
      return ''

    case ActionTypes.LOAD_COMBINED_GAMES_FAIL:
      return action.error

    case ActionTypes.DELETE_GAME_REQUEST:
      return ''

    case ActionTypes.DELETE_GAME_FAIL:
      return action.error

    case ActionTypes.SAVE_GAME_REQUEST:
      return ''

    case ActionTypes.SAVE_GAME_FAIL:
      return action.error

    case ActionTypes.SAVE_PLAYING:
      return ''

    case ActionTypes.CANCEL_PLAYING:
      return ''

    case ActionTypes.SET_SEARCH:
      return ''

    case ActionTypes.LOAD_SCOREBOARD_GAMES_REQUEST:
      return ''

    case ActionTypes.LOAD_SCOREBOARD_GAMES_FAIL:
      return action.error

    default:
      return state
  }
}

export default errorReducer
