import PropTypes from 'prop-types';
import React, { Component } from 'react'
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux'
import { gamesToStats } from '../selectors/selectors'
import { loadUsers } from '../actions/userActions'
import { loadGame, loadGames, loadFriendScoreboard } from '../actions/gameActions'
import { startNewGame } from '../actions/newGameActions'
import Gear from 'react-icons/lib/io/gear-a'
import Scoreboard from 'react-icons/lib/io/stats-bars'
import Header from '../components/common/Header'
import HeaderButtonLeft from '../components/common/HeaderButtonLeft'
import HeaderButtonRight from '../components/common/HeaderButtonRight'
import StatsContainer from '../containers/StatsContainer'
import Footer from '../components/common/Footer'

class UserPage extends Component {
  constructor(props) {
    super(props)

    this.handleLoadGames = this.handleLoadGames.bind(this)
    this.handleLoadGame = this.handleLoadGame.bind(this)
    this.handleNewGame = this.handleNewGame.bind(this)
    this.handleLoadFriendScoreboard = this.handleLoadFriendScoreboard.bind(this)
    this.handleLoadSettings = this.handleLoadSettings.bind(this)
  }

  componentDidMount() {
    const { loadGames, currentUser } = this.props
    if (currentUser._id) {
      loadGames(currentUser._id)
    }
  }

  handleLoadGames() {
    this.props.history.push('/games')
  }

  handleLoadGame(game) {
    const { games, loadGame } = this.props
    game = games.find(g => g._id === game._id)
    loadGame(game)
    this.props.history.push('/game')
  }

  handleNewGame() {
    const { currentUser, startNewGame, loadUsers } = this.props
    startNewGame(currentUser)
    loadUsers()
    this.props.history.push('/playing')
  }

  handleLoadFriendScoreboard() {
    const { currentUser, loadFriendScoreboard } = this.props
    const friends = currentUser.friends.concat(currentUser._id)
    loadFriendScoreboard(friends)
    this.props.history.push('/scoreboard')
  }

  handleLoadSettings() {
    this.props.history.push('/settings')
  }

  render() {
    const { currentUser, stats, loading } = this.props

    return (
      <div className="page">
        <Header name={currentUser.name} />
        <HeaderButtonLeft onClick={this.handleLoadSettings}>
          <Gear />
        </HeaderButtonLeft>
        <HeaderButtonRight onClick={this.handleLoadFriendScoreboard}>
          <Scoreboard />
        </HeaderButtonRight>
        <StatsContainer
          games={stats}
          currentUser={currentUser}
          user={currentUser}
          clickGame={this.handleLoadGame}
          clickGames={this.handleLoadGames}
          loading={loading}
        />
        <Footer
          onClick={this.handleNewGame}
          name="New game"
        />
      </div>
    )
  }
}

UserPage.propTypes = {
  currentUser: PropTypes.object.isRequired,
  games: PropTypes.array.isRequired,
  loading: PropTypes.bool.isRequired,
  startNewGame: PropTypes.func.isRequired,
  loadGame: PropTypes.func.isRequired,
  loadFriendScoreboard: PropTypes.func.isRequired
}

const mapStateToProps = (state) => {
  const { currentUser, games, loading } = state

  const stats = gamesToStats(games, currentUser._id)

  return {
    currentUser,
    games,
    stats,
    loading
  }
}

UserPage = connect(
  mapStateToProps,
  { startNewGame, loadUsers, loadGame, loadGames, loadFriendScoreboard }
)(UserPage)

export default withRouter(UserPage)
