import PropTypes from 'prop-types';
import React from 'react';

const LoginForm = ({ onSubmit, name, password, onNameChange, onPasswordChange, error, submitLabel }) => (
  <form className="login-form" onSubmit={onSubmit}>
    <input className="login-input login-name" type="text" placeholder="Name" value={name} onChange={onNameChange} autoFocus />
    <input className="login-input" type="password" placeholder="Password" value={password} onChange={onPasswordChange} />
    {error && <div className="login-error">{error}</div>}
    <button className="login-button" type="submit">{submitLabel}</button>
  </form>
)

LoginForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  name: PropTypes.string,
  password: PropTypes.string,
  onNameChange: PropTypes.func.isRequired,
  onPasswordChange: PropTypes.func.isRequired,
  error: PropTypes.string,
  submitLabel: PropTypes.string.isRequired
}

export default LoginForm
