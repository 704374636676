import PropTypes from 'prop-types';
import React from 'react'
import Shooter from '../components/Shooter'
import ShooterShots from '../components/ShooterShots'

const ShooterContainer = ({ shooter, shots, otherShots, onChange }) => (
  <div className="shooter-container">
    <Shooter shooter={shooter} />
    <ShooterShots shots={shots} onChange={onChange} />
  </div>
)

//<Shots shots={otherShots} />

ShooterContainer.propTypes = {
  shooter: PropTypes.object.isRequired,
  shots: PropTypes.array.isRequired,
  otherShots: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired
}

export default ShooterContainer
