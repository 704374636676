import initialState from './initialState'
import ActionTypes from '../actions/actionTypes'

const loginReducer = (state = initialState.loginState, action) => {
  switch (action.type) {
    
    case ActionTypes.CHANGE_NAME:
      return { ...state, name: action.name }

    case ActionTypes.CHANGE_PASSWORD:
      return { ...state, password: action.password }

    case ActionTypes.SWITCH_LOGIN:
      return { ...state, login: !state.login }

    case ActionTypes.LOGIN_SUCCESS:
      return initialState.loginState

    case ActionTypes.SIGNUP_SUCCESS:
      return initialState.loginState

    default:
      return state

  }
}

export default loginReducer
