// src/routes.js

import React from 'react';
import { Route, Switch } from 'react-router-dom';

import UserPage from './pages/UserPage';
import GamesPage from './pages/GamesPage';
import GamePage from './pages/GamePage';
import EditGamePage from './pages/EditGamePage';
import PlayingPage from './pages/PlayingPage';
import OrderPage from './pages/OrderPage';
import PlayGamePage from './pages/PlayGamePage';
import GameOverPage from './pages/GameOverPage';
import LoginPage from './pages/LoginPage';
import OtherUserPage from './pages/OtherUserPage';
import OtherGamesPage from './pages/OtherGamesPage';
import FriendScoreboardPage from './pages/FriendScoreboardPage';
import SettingsPage from './pages/SettingsPage';
import FriendsPage from './pages/FriendsPage';
import HowToPlayPage from './pages/HowToPlayPage';
import PrivacyPage from './pages/PrivacyPage';
import NotFoundPage from './pages/NotFoundPage';

export default (
  <Switch>
    <Route exact path="/" component={UserPage} />
    <Route exact path="/games" component={GamesPage} />
    <Route exact path="/game" component={GamePage} />
    <Route exact path="/editGame" component={EditGamePage} />
    <Route exact path="/playing" component={PlayingPage} />
    <Route exact path="/order" component={OrderPage} />
    <Route exact path="/playGame" component={PlayGamePage} />
    <Route exact path="/gameOver" component={GameOverPage} />
    <Route exact path="/login" component={LoginPage} />
    <Route exact path="/other" component={OtherUserPage} />
    <Route exact path="/otherGames" component={OtherGamesPage} />
    <Route exact path="/scoreboard" component={FriendScoreboardPage} />
    <Route exact path="/settings" component={SettingsPage} />
    <Route exact path="/friends" component={FriendsPage} />
    <Route exact path="/play" component={HowToPlayPage} />
    <Route exact path="/privacy" component={PrivacyPage} />
    <Route path="*" component={NotFoundPage} />
  </Switch>
);
