import PropTypes from 'prop-types';
import React from 'react'
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux'
import { savePlaying, startGame } from '../actions/newGameActions'
import { combinedToStats } from '../selectors/selectors'
import Header from '../components/common/Header'
import OrderContainer from '../containers/OrderContainer'
import MatchUpContainer from '../containers/MatchUpContainer'
import SecondFooter from '../components/common/SecondFooter'
import Footer from '../components/common/Footer'

class OrderPage extends React.Component {
  constructor(props) {
    super(props)

    this.handleChangeOrder = this.handleChangeOrder.bind(this)
    this.handleStart = this.handleStart.bind(this)
    this.handleBack = this.handleBack.bind(this)
  }

  componentDidMount() {
    if (this.props.playing.length === 0) {
      this.props.history.push('/')
    }
  }

  handleChangeOrder(players) {
    this.props.savePlaying(players)
  }

  handleStart(event) {
    event.preventDefault()
    const { playing, startGame } = this.props
    startGame(playing)
    this.props.history.push('/playGame')
  }

  handleBack() {
    this.props.history.push('/playing')
  }

  render() {
    const { playing, combinedGames, loading } = this.props
    const combinedGamesLength = combinedGames.length > 0
    const stats = combinedGamesLength ? combinedToStats(playing, combinedGames) : {}

    return (
      <div className="page">
        <Header name="Order" />
        <div className="page-container two-footer">
          <OrderContainer players={playing} onChange={this.handleChangeOrder} />
          { combinedGamesLength && <MatchUpContainer players={playing} stats={stats} loading={loading} /> }
        </div>
        <SecondFooter onClick={this.handleBack} name="Back" />
        <Footer onClick={this.handleStart} name="Start" />
      </div>
    );
  }
}

OrderPage.propTypes = {
  playing: PropTypes.array.isRequired,
  savePlaying: PropTypes.func.isRequired
}

const mapStateToProps = (state) => {
  const { playing, combinedGames, loading } = state

  return {
    playing,
    combinedGames,
    loading
  }
}

OrderPage = connect(
  mapStateToProps,
  { savePlaying, startGame }
)(OrderPage)

export default withRouter(OrderPage)
