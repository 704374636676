import initialState from './initialState'
import ActionTypes from '../actions/actionTypes'

const sortByReducer = (state = initialState.sortBy, action) => {
  switch(action.type) {

    case ActionTypes.SET_SORTBY:
      return action.sortBy

    default:
      return state
  }
}

export default sortByReducer
