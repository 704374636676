import React, { Component } from 'react'
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux'
import { changeName, changePassword, login, signup, changeLogin } from '../actions/loginActions'
import { setError } from '../actions/userActions'
import LoginForm from '../components/LoginForm'

class LoginPage extends Component {
  constructor(props) {
    super(props)

    this.handleNameChange = this.handleNameChange.bind(this)
    this.handlePasswordChange = this.handlePasswordChange.bind(this)
    this.handleLogin = this.handleLogin.bind(this)
    this.handleSignup = this.handleSignup.bind(this)
    this.handleSwitch = this.handleSwitch.bind(this)
  }

  componentDidMount() {
    const { currentUser, history } = this.props;

    console.log('currentUser', currentUser);
    if (currentUser._id) {
      history.replace('/');
    }
  }

  componentWillReceiveProps(nextProps) {
    const { currentUser: nextCurrentUser } = nextProps;
    const { currentUser, history } = this.props;

    if (nextCurrentUser._id && !currentUser._id) {
      console.log('success');
      history.replace('/');
    }
  }

  // handles change on name input
  handleNameChange(evt) {
    this.props.changeName(evt.target.value)
  }

  // handles change on password input
  handlePasswordChange(evt) {
    this.props.changePassword(evt.target.value)
  }

  // handles login button press
  handleLogin(evt) {
    evt.preventDefault()
    const { loginState, login, setError } = this.props
    const { name, password } = loginState
    if (name && password) { // check if values for name and password
      login(name, password)
    } else {
      setError('Please enter a name or password')
    }
  }

  // handles signup button press
  handleSignup(evt) {
    evt.preventDefault()
    const { loginState, signup, setError } = this.props
    const { name, password } = loginState
    if (name && password) { // check if values for name and password
      signup(name, password)
    } else {
      setError('Please enter a name or password')
    }
  }

  // handles switch button press
  handleSwitch() {
    this.props.changeLogin()
  }

  render() {
    const { loginState, loading, error } = this.props
    const { login, name, password } = loginState
    let message, submitLabel, switchLabel, loginHandler

    if (login) {
      message = 'tens'
      submitLabel = 'Login'
      switchLabel = 'Sign up'
      loginHandler = this.handleLogin
    } else {
      message = 'Go ten for ten'
      submitLabel = 'Sign up'
      switchLabel = 'Login'
      loginHandler = this.handleSignup
    }

    return (
      <div className="login-page">
        <div className="login-message">
          <div className="login-title">{message}</div>
        </div>
        <LoginForm
          onSubmit={loginHandler}
          name={name}
          password={password}
          onNameChange={this.handleNameChange}
          onPasswordChange={this.handlePasswordChange}
          error={error}
          submitLabel={submitLabel}
        />
        {loading && <div className="login-loading">loading...</div>}
        <div className="login-switch">
          <div className="switch-login" onClick={this.handleSwitch}>{switchLabel}</div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = ({ currentUser, loginState, loading, error }) => ({ currentUser, loginState, loading, error })

LoginPage = connect(
  mapStateToProps,
  { setError, changeName, changePassword, login, signup, changeLogin }
)(LoginPage)

export default withRouter(LoginPage)
