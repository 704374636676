import PropTypes from 'prop-types';
import React, { Component } from 'react'
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux'
import { scoreboardToStats } from '../selectors/selectors'
import { removeScoreboard } from '../actions/gameActions'
import { loadOtherUser, setSortBy } from '../actions/userActions'
import Header from '../components/common/Header'
import ScoreboardContainer from '../containers/ScoreboardContainer'
import Footer from '../components/common/Footer'

class FriendScoreboardPage extends Component {
  constructor(props) {
    super(props)

    this.handleClickUser = this.handleClickUser.bind(this)
    this.handleChangeSort = this.handleChangeSort.bind(this)
    this.handleBack = this.handleBack.bind(this)
  }

  // componentWillMount() {
  //   if (this.props.users.length === 0) {
  //     browserHistory.push('/')
  //   }
  // }

  handleClickUser(player) {
    this.props.loadOtherUser(player)
    this.props.history.push('/other')
  }

  handleChangeSort(sortBy) {
    this.props.setSortBy(sortBy)
  }

  handleBack() {
    this.props.removeScoreboard()
    this.props.history.goBack()
  }

  render() {
    const { users, stats, friends, loading, sortBy } = this.props
    return (
      <div className="page">
        <Header name="Scoreboard" />
        <ScoreboardContainer
          friends={friends}
          users={users}
          stats={stats}
          loading={loading}
          onClickUser={this.handleClickUser}
          onChange={this.handleChangeSort}
          sortBy={sortBy}
        />
        <Footer onClick={this.handleBack} name="Back"/>
      </div>
    )
  }
}

FriendScoreboardPage.propTypes = {
  currentUser: PropTypes.object.isRequired,
  users: PropTypes.array.isRequired,
  stats: PropTypes.object.isRequired,
  scoreBoardGames: PropTypes.array,
  friends: PropTypes.array.isRequired,
  loading: PropTypes.bool
}

const mapStateToProps = (state) => {
  const { currentUser, users, scoreboardGames, loading, sortBy } = state

  const friends = currentUser.friends.concat(currentUser._id)
  const stats = scoreboardToStats(scoreboardGames, friends)

  return { currentUser, users, stats, scoreboardGames, friends, loading, sortBy }
}

FriendScoreboardPage = connect(
  mapStateToProps,
  { loadOtherUser, removeScoreboard, setSortBy }
)(FriendScoreboardPage)

export default withRouter(FriendScoreboardPage)
