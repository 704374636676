import React, { Component } from 'react'
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux'
import { logout, loadUsers } from '../actions/userActions'
import Header from '../components/common/Header'
import SettingsContainer from '../containers/SettingsContainer'
import Footer from '../components/common/Footer'

class SettingsPage extends Component {
  constructor(props) {
    super(props)

    this.handleBack = this.handleBack.bind(this)
    this.handleLoadFriends = this.handleLoadFriends.bind(this)
    this.handleLogout = this.handleLogout.bind(this)
    this.handlePlay = this.handlePlay.bind(this)
  }

  handleLoadFriends() {
    this.props.loadUsers()
    this.props.history.push('/friends')
  }

  handlePlay() {
    this.props.history.push('/play')
  }

  handleLogout() {
    this.props.logout()
    this.props.history.push('/')
  }

  handleBack() {
    this.props.history.goBack()
  }

  render() {
    return (
      <div className="page">
        <Header name="Settings" />
        <SettingsContainer
          onClickFriends={this.handleLoadFriends}
          onClickPlay={this.handlePlay}
          onClickLogout={this.handleLogout}
        />
        <Footer onClick={this.handleBack} name="Back"/>
      </div>
    )
  }
}

SettingsPage = connect(
  null,
  { logout, loadUsers }
)(SettingsPage)

export default withRouter(SettingsPage)
