import React from 'react';

import Header from '../components/common/Header';

const handleEmail = () => {
  window.open('mailto:paklein3@gmail.com?subject=Privacy', '_blank');
};

const styles = {
  modified: {
    color: '#AAA',
    fontSize: 12,
    pt: 10,
    fontWeight: 600
  },
  header: {
    fontSize: 18,
    color: '#333',
    fontWeight: 600,
    mt: 20
  },
  subHeader: {
    fontSize: 16,
    color: '#333',
    fontWeight: 600,
    mt: 10
  },
  paragraph: {
    fontSize: 14,
    color: '#777',
    padding: '10px 0',
    fontWeight: 600
  },
  questionParagraph: {
    fontSize: 14,
    color: '#777',
    padding: '10px 0',
    fontWeight: 600,
    mb: 60
  },
  link: {
    display: 'inline',
    color: '#AAA',
    cursor: 'pointer',
    fontWeight: 600
  }
};

const PrivacyView = () => (
  <div className="page">
    <Header>Privacy Policy</Header>
    <div className="container">
      <div style={styles.modified}>Last Modified: April 30, 2019</div>
      <div style={styles.paragraph}>
        {
          'Streaking provides a place to track activities. When you use this product—and any others we roll out—you’ll inevitably share some information with us. We get that that can affect your privacy. So we want to be upfront about the information we collect, how we use it, whom we share it with, and the choices we give you to control, access, and update your information. That’s why we’ve written this privacy policy. And it’s why we’ve tried to write it in a way that’s blissfully free of the legalese that often clouds these documents. Of course, if you still have questions about anything in our privacy policy, email us at '
        }
        <div
          role="button"
          tabIndex={-1}
          onKeyUp={() => {}}
          style={styles.link}
          onClick={handleEmail}
        >
          pklein3@gmail.com
        </div>.
      </div>
      <div style={styles.header}>Information We Collect</div>
      <div style={styles.paragraph}>
        There are two basic ways we collect information: Information you choose to give us.
        Information we get automatically when you use our product. Here’s a little more detail on
        each of these categories.
      </div>
      <div style={styles.subHeader}>Information You Choose to Give Us</div>
      <div style={styles.paragraph}>
        When you interact with our product, we collect the information that you choose to share with
        us. For example, you may set up a basic Streaking account, so we need to collect a few
        important details about you: your name, an email address, and a password. We also collect
        your events and activities. Also, when you contact Streaking Help or communicate with us in
        any other way, we’ll collect whatever information you volunteer.
      </div>
      <div style={styles.subHeader}>Information We Get Automatically When You Use Our Product</div>
      <div style={styles.paragraph}>
        When you use our product, we collect information about which of those product you’ve used
        and how you’ve used them. We might know, for instance, how many days in a row you have used
        the product, how often you do certain activities, and how many events you are tracking.
      </div>
      <div style={styles.header}>How We Use Information</div>
      <div style={styles.paragraph}>
        What do we do with the information we collect? The short answer is: Privately and securely
        store your information, as well as, use it to provide you with a product that we
        relentlessly improve. We develop, improve, deliver, maintain, and protect our product,
        communicate with you, monitor and analyze trends and usage, enhance the safety and security
        of our product, verify your identity and prevent fraud or other unauthorized or illegal
        activity, to enhance the product and your experience with them. We may also store some
        information locally on your device. For example, we may store information as local cache so
        that you can open the app and view content faster. Although we welcome Müsing from all over
        the world, keep in mind that no matter where you live or where you happen to use our
        product, you consent to us processing and transferring information in and to the United
        States and other countries whose data-protection and privacy laws may offer fewer
        protections than those in your home country.
      </div>
      <div style={styles.header}>How We Share Information</div>
      <div style={styles.paragraph}>
        We may share information about you in the following ways: With service providers, sellers,
        and partners. We may share information about you with service providers who perform product
        on our behalf, sellers that provide goods through our product, and business partners that
        provide product and functionality. With third parties for legal reasons. We may share
        information about you if we reasonably believe that disclosing the information is needed to:
        comply with any valid legal process, government request, or applicable law, rule, or
        regulation, investigate, remedy, or enforce potential Terms of Service violations, protect
        the rights, property, and safety of us, our users, or others, or detect and resolve any
        fraud or security concerns. With third parties as part of a merger or acquisition. If
        Streaking gets involved in a merger, asset sale, financing, or acquisition of some portion
        of our business to another company, we may share your information with that company before
        and after the transaction closes. In the aggregate. We may also share with third parties,
        such as advertisers, aggregated or de-identified information that cannot reasonably be used
        to identify you.
      </div>
      <div style={styles.header}>Analytics and Advertising Product Children</div>
      <div style={styles.paragraph}>
        We don’t direct our Product to anyone under 13. And that’s why we do not knowingly collect
        personal information from anyone under 13.
      </div>
      <div style={styles.header}>Revisions to the Privacy Policy</div>
      <div style={styles.paragraph}>
        We may change this privacy policy from time to time. But when we do, we’ll let you know one
        way or another. Sometimes, we’ll let you know by revising the date at the top of the privacy
        policy that’s available on our website and mobile application. Other times, we may provide
        you with additional notice (such as adding a statement to our websites’ homepages).
      </div>
      <div style={styles.header}>Questions</div>
      <div style={styles.questionParagraph}>
        If you have any questions regarding our Privacy Policy, please contact{' '}
        <div
          role="button"
          tabIndex={-1}
          onKeyUp={() => {}}
          style={styles.link}
          onClick={handleEmail}
        >
          paklein3@gmail.com
        </div>.
      </div>
    </div>
  </div>
);

export default PrivacyView;
