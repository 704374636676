import PropTypes from 'prop-types';
import React from 'react'
import { ResponsiveContainer, BarChart, XAxis, YAxis, CartesianGrid, Bar } from 'recharts'

const Chart = ({ data, xKey, yKey }) => {
  return (
    <ResponsiveContainer width="100%" height="100%">
      <BarChart data={data} barCategoryGap={2} margin={{top: 20, right: 100, bottom: 20, left: 40}}>
        <XAxis dataKey={xKey} tickLine={false} />
        <YAxis dataKey={yKey} interval={0} domain={[0, 100]} tickLine={false} />
        <CartesianGrid horizontal={false} vertical={false} />
        <Bar dataKey={yKey} fill="#424242" />
      </BarChart>
    </ResponsiveContainer>
  )
}

Chart.propTypes = {
  data: PropTypes.array.isRequired,
  xKey: PropTypes.string,
  yKey: PropTypes.string
}

export default Chart
