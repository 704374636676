import PropTypes from 'prop-types';
import React from 'react'

const ScoreboardRow = ({ onClickUser, row, onClick }) => {

  const handleClickUser = () => onClickUser ? onClickUser({_id: row._id, name: row.name}) : null
  const handleClick = (sortBy) => onClick ? onClick(sortBy) : null

  return (
    <div className="match-up-row">
        <div className="match-up-col" onClick={handleClickUser}>{row.name}</div>
        <div className="match-up-col" onClick={() => handleClick('games')}>{row.games}</div>
        <div className="match-up-col" onClick={() => handleClick('wins')}>{row.wins}</div>
        <div className="match-up-col" onClick={() => handleClick('best')}>{row.best}</div>
        <div className="match-up-col" onClick={() => handleClick('shots')}>{row.shots}</div>
        <div className="match-up-col" onClick={() => handleClick('avg')}>{row.avg}</div>
    </div>
  )
}

ScoreboardRow.propTypes = {
  row: PropTypes.object.isRequired,
  onClickUser: PropTypes.func,
  onClick: PropTypes.func
}

export default ScoreboardRow
